<template>
  <head>
    <title v-text="pageTitle"></title>
  </head>
  <body>
  <div class='row'>
    <div class='col'>
      <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      v-show="getArea.area != null">
        <el-menu-item index="5">
          <el-input v-model="numero" type="number" v-on:keypress="onKeyPress" placeholder="Ingrese ticket"></el-input>
        </el-menu-item>
        <el-menu-item index="7" @click="misTop5">Mi top5</el-menu-item>
        <el-menu-item index="6" @click="misRecurrentes">Mis recurrentes</el-menu-item>
        <el-menu-item index="1" @click="misTickets">Mis Tickets</el-menu-item>
        <el-menu-item index="2" @click="xurl('/impusum')">Summary Report</el-menu-item>
        <el-menu-item index="3" @click="xurl('/ImpuDiario')">Daily Report</el-menu-item>
        <el-sub-menu index="4">
            <template #title>Extras</template>
            <el-menu-item index="4-1" @click="xurl('/home')">Home</el-menu-item>
            <el-menu-item index="4-2" @click="xurl('/')">Login</el-menu-item>
            <el-sub-menu index="4-4" v-show="getArea.area === 'it'">
              <template #title>hours</template>
              <el-menu-item index="4-4-1" @click="xurl('/top5')">Top 5</el-menu-item>
              <el-menu-item index="4-4-2" @click="xurl('/impu')">Detailed Report</el-menu-item>
            </el-sub-menu>
            <el-menu-item index="4-5" @click="salir">Cerrar Sesion de {{this.getArea.user}}</el-menu-item>
        </el-sub-menu>
        <el-menu-item index="9">
          <el-button plain size="mini" type = "primary" @click="xurl('/Altatkt')" class="btn btn-primary mb-2">+</el-button>
        </el-menu-item>
        <el-menu-item index="10" @click="this.$refs.Notas.textoVisible = true">mis notas</el-menu-item>
        <!-- xurl('/Borrador') -->
      </el-menu>
    </div>
  </div>
  <router-view/>
  </body>
  <Borrador ref="Notas"/>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import shared from './shared/shared'
import { ref } from 'vue'
export default {
  extends: shared,
  setup () {
    const activeIndex = ref('1')
    const handleSelect = (key, keyPath) => {
      console.log(key, keyPath)
    }
    return {
      activeIndex,
      handleSelect
    }
  },
  data () {
    return { pageTitle: 'Título por defecto', numero: 0 }
  },
  computed: {
    ...mapGetters(['getArea'])
  },
  methods: {
    onKeyPress (event) {
      if (!this.isNumber(event)) return false
      if (event.keyCode === 13) { // Código de la tecla Enter
        this.$router.push('/?ticket=' + this.numero)
      }
    },
    xurl (param) {
      this.$router.push(param)
    },
    ...mapMutations(['setUser', 'setToken', 'setArea']),
    salir () {
      this.setUser(null)
      this.setArea(null)
      this.setToken(null)
      this.successMsg('Sesion cerrada, gracias por utilizar el portal de tickets.')
      this.$router.push('/')
    },
    misRecurrentes () {
      this.$router.push('/Tickets/?precurrente=S')
    },
    misTickets () {
      this.$router.push('/Tickets/?pestado=A')
    },
    misTop5 () {
      this.$router.push('/Top5/?pprof=S')
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* background-color: #8d8c8c; */
  color: #bbbfc0;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
table {
  color: white;
}
th {
  /* background: white; */
  border-color: rgb(120,120,120);
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.4);
  color: rgb(208, 208, 208);
}
td {
  border: 0px solid rgb(120, 120, 120);
  color: rgb(170, 170, 170);
  font-weight: bold !important;
}
tr.blue th {
  background: #2c3e50;
  border: 2px solid #2c3e50;
  color: rgb(192, 192, 192);
}
tr.gray td {
  background: gray;
  border: 2px solid gray;
  position: sticky;
  color: rgb(120, 120, 120);
}
.el-menu-item:hover{
  background-color: #222933 ! important;
  color: rgb(42, 154, 230) ! important;
}
.el-sub-menu__title:hover{
  background-color: #222933 ! important;
  color: rgb(42, 154, 230) ! important;
}
.el-sub-menu__title{
  background-color: #222933 ! important;
  color: rgb(190, 182, 176)! important;
}
tr:hover {
  border: 2px solid rgb(42, 154, 230);
}
</style>
