<template>
   <div>
     <div class="row">
       <!-- <div class="col-md-5"></div> -->
        <div class="col-md-2">
          <datepicker placeholder="Select Date"></datepicker>
          <h1>Ingresar</h1>
          <form @submit.prevent='login'>
            <el-input v-model='username' placeholder='usuario' />
            <br />
            <br />
            <el-input v-model='password' placeholder='clave' type='password' />
            <br />
            <br />
            <button type='submit' class='btn btn-primary mb-2'>Login</button>
          </form>
        </div>
        <div class="col-md-5"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import shared from '../shared/shared'
import config from '../../public/config.json'
export default {
  name: 'Login',
  props: {
    ticket: String
  },
  created () {
    document.title = 'Ingresar a Tickets'
    this.enrutamiento()
    // this.closeFSL(this)
  },
  extends: shared,
  data: () => {
    return {
      // loaded: false,
      username: '',
      password: ''
    }
  },
  mounted () {
  },
  beforeMount () {
    if (this.$route.query.jwt) {
      this.setToken(this.$route.query.jwt)
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getArea'])
  },
  methods: {
    enrutamiento () {
      if (this.$route.query.pprof === 'S') {
        this.$router.replace('/Top5/?pprof=S')
      } else if (this.$route.query.precurrente === 'S') {
        this.$router.replace('/Tickets/?precurrente=S')
      } else if (this.ticket || this.$route.query.ticket) {
        this.$router.replace('/TicketInfo/' + this.$route.query.ticket)
      } else if (this.$route.query.parea) {
        this.$router.push('/Tickets/?parea=' + this.$route.query.parea + '&pestado=' + this.$route.query.pestado)
      } else if (this.$route.query.pestado === 'A') {
        this.$router.replace('/Tickets/?pestado=A')
      } else if (this.getArea.area === 'it') {
        this.$router.replace('/Top5/?pprof=S')
      } else if (this.getArea.area !== null) {
        if (this.$router.options.history.state.back) {
          if (this.$router.options.history.state.back.toString().substring(0, 1) === '/') {
            this.$router.go(-1)
          }
        } else {
          this.$router.replace('/Home')
        }
      }
    },

    ...mapMutations(['setUser', 'setToken', 'setArea']),
    async login (e) {
      console.log(config.ip)
      e.preventDefault()
      fetch('http://' + config.ip + '/login/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          usrNombre: this.username,
          usrClave: this.password
        })
      })
        .then(async response => {
          const { user, area, JWT } = await response.json()
          this.setUser(user)
          this.setArea(area)
          this.setToken(JWT)
          this.successMsg('Acceso Correcto, bienvenido ' + user)
          if (area === 'asdadasdsa') {
            this.$router.push('/variablesLarga')
          } else {
            this.enrutamiento()
          }
        })
        .catch(error => {
          this.setToken(null)
          this.setUser(null)
          this.setArea(null)
          console.error('Mensaje de error: ', error)
          this.errorMsg(error)
        })
    }
  }
}
</script>
<style scoped>
body
{
    margin:auto;
    width:1024px;
    padding:10px;
    background-color:#4d3737;
    font-size:14px;
    font-family:Verdana;
}
</style>
