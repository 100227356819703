<template>
<head>
  <title v-text="pageTitle"></title>
</head>
<div v-if="isLoggedIn" class="container-fluid" v-on:keyup.ctrl.shift.f="focusImpu">
  <back/>
  <div class="row">
    <div class="col-md-4">
      <el-card shadow="always">
        <table>
          <tr>
            <td> <H2>#{{ticket.usrSttickNroticket}} - {{ticket.usrSttickDescrip}} </H2>
            </td>
            <td>
            </td>
            <td>
              <el-progress type="dashboard" :percentage="ticket.usrSttickConProg">
                <template #default="{ percentage }">
                  <span class="percentage-value"><strong>{{ percentage }}%</strong></span>
                  <br/>
                  <span class="percentage-label"><strong> {{ticket.usrSttickSaldo}} hs </strong></span>
                </template>
              </el-progress>
            </td>
          </tr>
        </table>
        <el-tooltip
        class="box-item"
        effect="dark"
        content="Copiar enlace"
        placement="top-start">
          <el-button size="mini" type="primary" @click="genUrl" icon="el-icon-connection" circle/>
        </el-tooltip>
      </el-card>
    </div>
    <div class="col-md-8">
      <el-card shadow="always">
      <div class="row">
        <div class="col-md-2">
          <label v-if='ticket.usrSttickFechaAlta'> Alta </label>
          <el-input v-model="ticket.usrSttickFechaAlta" readonly />
        </div>
        <div class="col-md-2">
          <label  v-if='ticket.usrSttickFechaFin'> Fin </label>
          <el-input v-model="ticket.usrSttickFechaFin" readonly />
        </div>
        <div class="col-md-2">
          Empresa
          <el-input v-model="ticket.usrSttickEmpresa" readonly />
        </div>
        <div class="col-md-2">
          Solicitante
          <el-input v-model="ticket.usrSttickPropie" readonly />
        </div>
        <div class="col-md-2">
          Usuario
          <el-input v-model="ticket.usrSttickUsuario" readonly />
        </div>
        <div class="col-md-2">
          Recurrente
          <el-select v-model="ticket.usrSttickRecurrente" filterable clearable placeholder="Recurrente" :disabled="true">
            <el-option
              v-for="item in optRecurrentes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="row" v-if="getArea.area === 'it'"> -->
      <div class="row">
        <div class="col-md-2">Proyecto
          <el-input v-model="ticket.usrSttickProyecto" readonly />
        </div>
        <div class="col-md-2">Orden 1
          <el-input v-model="ticket.usrSttickOrden1" readonly />
        </div>
        <div class="col-md-2">Orden 2
          <el-input v-model="ticket.usrSttickOrden2" readonly />
        </div>
        <div class="col-md-2">Categoria
          <el-input v-model="ticket.usrSttickCategoria" readonly />
        </div>
        <div class="col-md-2">Sub Categoria
          <el-input v-model="ticket.usrSttickSubcat" readonly />
        </div>
        <div class="col-md-2">Prioridad
          <el-input v-model="ticket.usrSttickOrdusr" readonly />
        </div>
        <div class="col-md-2">
          <!-- <el-button type="primary" size="mini" icon="el-icon-edit" @click="_showDialog" circle/> -->
        </div>
      </div>
      <el-tooltip
        class="box-item"
        effect="dark"
        content="Editar cabecera"
        placement="top-start">
      <el-button type="primary" size="mini" icon="el-icon-edit" @click="_showDialog" circle/>
      </el-tooltip>
      </el-card>
    </div>
  </div>
  <div class="row container-fluid">
      <el-tag v-if="ticket.usrSttickEstado=='S'">Asignado</el-tag>
      <el-tag v-if="ticket.usrSttickEstado=='F'">Cerrado</el-tag>
      <el-tag type="success" v-if="ticket.usrSttickEstado=='C'">En curso</el-tag>
      <el-tag type="info" v-if="ticket.usrSttickEstado=='P'">Sin procesar</el-tag>
      <el-tag type="warning" v-if="ticket.usrSttickEstado=='D'">Demorado</el-tag>
      <el-tag type="danger" v-if="ticket.usrSttickEstado=='U'">Bloqueado {{esperaRsm}}</el-tag>
  </div>
  <div>
    <!-- class='container-fluid' -->
    <div class='row'>
      <div class="col-md-12 text-md-right">
        <el-collapse v-model="activeHeaderName" accordion>
          <el-collapse-item title="Definicion" name="1">
            <h5>
            <el-button type="primary" size="mini" icon="el-icon-edit" @click="mostrarDEFINICION('M', ticket.usrSttickTexto)" circle/>
            <br/>
            <span v-html="highlightPend(ticket.usrSttickTexto)"></span>
            </h5>
          </el-collapse-item>
          <!-- <el-collapse-item title="Resumen de acciones" name="2" v-if="getArea.area === 'it'"> -->
          <el-collapse-item title="Resumen de acciones" name="2">
            <h5>
            <span v-if=ticket.usrSttickAcrea v-html="wrapTextWithStrong(ticket.usrSttickAcrea)"></span>
            </h5>
          </el-collapse-item>
          <!-- <el-collapse-item title="Observaciones" name="3" v-if="getArea.area === 'it'"> -->
          <el-collapse-item title="Observaciones" name="3">
            <el-button size="mini" type="primary" @click="copyText">
              <el-icon><CopyDocument />
              </el-icon>
            </el-button>
            <el-button type="primary" size="mini" icon="el-icon-edit" @click="_showDialogObserv()" circle/>
            <p style='white-space: pre-line' v-if='ticket.usrSttickTextodesa'>
              {{ ticket.usrSttickTextodesa}}
            </p>
          </el-collapse-item>
          <!-- <el-collapse-item title="Objetos Afectados" name="4" v-if="getArea.area === 'it'"> -->
          <el-collapse-item title="Objetos Afectados" name="4">
            <p style='white-space: pre-line' v-if='ticket.usrSttickObjafectados'>
              Afectados {{ticket.usrSttickObjafectados}}
            </p>
          </el-collapse-item>
          <!-- <el-collapse-item title="Entregables" name="5" v-if="getArea.area === 'it'"> -->
          <el-collapse-item title="Entregables" name="5">
            <p style='white-space: pre-line' v-if='ticket.usrSttickEntr'>
              {{ticket.usrSttickEntr}}
            </p>
          </el-collapse-item>
        </el-collapse>
        <div class='col-md-3'>
        </div>
        <div class='col-md-3'>
        </div>
        <div class='col-md-3'>
        </div>
        <div class='col-md-3'>
        </div>
      </div>
    </div>
    <br>
    <div class="container-fluid">
      <el-tabs :tab-position="'left'" v-model="activeName" @tab-click="handleClick">
          <!-- <el-tab-pane label="Pendientes" name="1" v-if="getArea.area === 'it'"> -->
          <el-tab-pane label="Pendientes" name="1">
            <div class='row'>
              <div class="col-md-10">
              </div>
              <div class="col-md-2">
                <el-button size="mini" type="success" icon="el-icon-plus" @click="_additemTareas()" plain>nuevo item</el-button>
              </div>
            </div>
            <br/>
            <div id='message' div class='col-md-12'>
              <table id='table_id'
                    data-toggle='table'
                    class='table table-condensed vue-table'
                    data-search='true'
                    data-filter-control='true'
                    >
                <thead>
                  <tr class="blue">
                      <th>Profesional</th>
                      <th>Fase / Tarea</th>
                      <th>Hs Estimado</th>
                      <th>Observacion</th>
                      <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>Resumen</strong></td>
                    <td></td>
                    <td><strong>{{ sumPend() }}</strong></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr v-for='(fas, index) in ticket.fases' :key='fas.usrFasesSttickNroticket'>
                    <td v-html="highlightMatches(nombreProf(fas.usrFasesProf))"></td>
                    <td v-html="highlightMatches(fas.usrFasesTareas)"></td>
                    <td v-html="highlightMatches(fas.usrFasesHs.toString())"></td>
                    <td v-html="highlightMatches(fas.usrFasesObserva)"></td>
                    <td>
                        <!-- <el-button type="primary" size="mini" icon="el-icon-search" @click="mostrarPendiente(this, this.fases, index)" circle/> -->
                        <el-button type="primary" size="mini" icon="el-icon-search" @click="_showDialogTareas('C',index)" circle/>
                        <el-button type="primary" size="mini" icon="el-icon-edit" @click="_showDialogTareas('M',index)" circle/>
                        <el-button type="primary" size="mini" icon="el-icon-delete" @click="_deleteitemTareas(index)" circle/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="Resueltas" name="2" v-if="getArea.area === 'it'"> -->
          <el-tab-pane label="Resueltas" name="2">
            <div class='row'>
              <div class="col-md-10">
              </div>
              <div class="col-md-2">
                <el-button size="mini" type="success" icon="el-icon-plus" @click="_additemResuelto()" plain>nuevo item</el-button>
              </div>
            </div>
            <br/>
            <div id='message' div class='col-md-12'>
              <table id='table_id'
                    data-toggle='table'
                    class='table table-condensed vue-table'
                    data-search='true'
                    data-filter-control='true'
                    >
                <thead>
                  <tr class="blue">
                      <th>Profesional</th>
                      <th>Fase / Tarea</th>
                      <th>Hs Estimado</th>
                      <th>Observacion</th>
                      <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>Resumen</strong></td>
                    <td></td>
                    <td><strong>{{ sumResuelto() }}</strong></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr v-for='(fas, index) in ticket.resueltos' :key='fas.usrResueltoSttickNroticket'>
                    <td v-html="highlightMatches(nombreProf(fas.usrResueltoProf))"></td>
                    <td v-html="highlightMatches(fas.usrResueltoTareas)"></td>
                    <td v-html="highlightMatches(fas.usrResueltoHs.toString())"></td>
                    <td v-html="highlightMatches(fas.usrResueltoObserva)"></td>
                    <td>
                        <!-- <el-button type="primary" size="mini" icon="el-icon-search" @click="mostrarPendiente(this, this.fases, index)" circle/> -->
                        <el-button type="primary" size="mini" icon="el-icon-search" @click="_showDialogResuelto('C',index)" circle/>
                        <el-button type="primary" size="mini" icon="el-icon-edit" @click="_showDialogResuelto('M',index)" circle/>
                        <el-button type="primary" size="mini" icon="el-icon-delete" @click="_deleteitemResuelto(index)" circle/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="Imputaciones" name="3" v-if="getArea.area === 'it'"> -->
          <el-tab-pane label="Imputaciones" name="3">
            <div class='row'>
              <div class="col-md-10">
              </div>
              <div class="col-md-2">
                <el-button size="mini" type="success" icon="el-icon-plus" @click="mostrarIMPU('A')" plain>nuevo item</el-button>
              </div>
            </div>
            <br/>
            <div id='message'>
              <el-input type='text' ref='search' v-model='search' placeholder= 'Filtrar por...' />
              <table id='table_id'
                    data-toggle='table'
                    class='table table-borderless table-condensed vue-table'
                    data-search='true'
                    data-filter-control='true'
                    >
                <thead>
                  <tr class="blue">
                      <th>Profesional</th>
                      <th>Fecha</th>
                      <th>Observacion</th>
                      <th>Hs</th>
                      <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>Resumen</strong></td>
                    <td></td>
                    <td></td>
                    <td><strong>{{ this.sumImp() }}</strong></td>
                    <td></td>
                  </tr>
                  <tr v-for='(imp, index) in filteredImp' :key='imp.usrImpHsiTick'>
                    <td v-if='imp.usrImpHsiImpHsProf' v-html="highlightMatches(imp.usrImpHsiImpHsProf)"></td>
                    <td>{{ imp.usrImpHsiImpHsFch.replace('T00:00:00Z[-03:00]','') }}</td>
                    <td v-html="highlightMatches(imp.usrImpHsiObs.substring(0,100).concat('...'))">
                    </td>
                    <td>{{ imp.usrImpHsiHs }}</td>
                    <td>
                      <!-- <el-button type="primary" size="mini" icon="el-icon-search" @click="mostrarIMPU('C', imp)" circle/> -->
                      <el-button type="primary" size="mini" icon="el-icon-search" @click="mostrarImputacionV3(this, this.filteredImp, index)" circle/>
                      <el-button type="primary" size="mini" icon="el-icon-edit" @click="mostrarIMPU('M', imp)" circle/>
                      <el-button type="primary" size="mini" icon="el-icon-delete" @click="eliminarItem(imp, 'DELETE')" circle/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Actividad" name="4">
            <div>
              <apexchart
                  type="bar"
                  :options="chartOptions_p"
                  :series="dataZonas_p"
                  v-if="isLoaded"
                  height="250"
                ></apexchart>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Resumen hs" name="5">
            <div>
              <el-input type='text' ref='search' v-model='search' placeholder= 'Filtrar por...' />
              <table id='table_idr'
                    data-toggle='table'
                    class='table table-borderless table-condensed vue-table'
                    data-search='true'
                    data-filter-control='true'
                    >
                <thead>
                  <tr class="blue">
                      <th>Profesional</th>
                      <th>Estimado</th>
                      <th>Original</th>
                      <th>Revision</th>
                      <th>Desvio</th>
                      <th>Imputado</th>
                      <th>Saldo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="prof in filteredDetHs" :key="prof.profesional">
                    <td v-html="(prof.profesional)"></td>
                    <td v-html="(prof.estimado)"></td>
                    <td v-html="(prof.original)"></td>
                    <td v-html="(prof.fueraalcance)"></td>
                    <td v-html="(prof.desvio)"></td>
                    <td v-html="(prof.imputado)"></td>
                    <td v-html="(prof.saldo)"></td>
                  </tr>
                  <tr>
                    <td><strong>Resumen</strong></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{ sum() }}</td>
                    <td>{{ sumSaldo() }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Proximas acciones" name="6">
            <div class='row'>
              <div class="col-md-10">
              </div>
              <div class="col-md-2">
                <el-button size="mini" type="success" icon="el-icon-plus" @click="mostrarTODO('A')" plain>nuevo item</el-button>
              </div>
            </div>
            <br/>
            <div id='message'>
              <table id='table_id'
                    data-toggle='table'
                    class='table table-borderless table-condensed vue-table'
                    data-search='true'
                    data-filter-control='true'
                    >
                <thead>
                  <tr class="blue">
                      <th>Profesional</th>
                      <th>Acciones</th>
                      <th>...</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for='acc in acciones' :key='acc.usrAccionesSttickNroticket'>
                    <td v-html="highlightMatches(acc.usrAccionesProf)"></td>
                    <td v-html="highlightMatches(acc.usrAccionesAccion.substring(0,100).concat('...'))"></td>
                    <td>
                    <el-button type="primary" size="mini" icon="el-icon-search" @click="mostrarTODO('C', acc)" circle/>
                    <el-button type="primary" size="mini" icon="el-icon-edit" @click="mostrarTODO('M', acc)" circle/>
                    <el-button type="primary" size="mini" icon="el-icon-delete" @click="eliminarItem(acc, 'DELETE')" circle/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Esperas" name="7">
            <div class='row'>
              <div class="col-md-10">
              </div>
              <div class="col-md-2">
                <el-button size="mini" type="success" icon="el-icon-plus" @click="mostrarESPE('A')" plain>nuevo item</el-button>
              </div>
            </div>
            <br/>
            <div id='message'>
              <el-input type='text' ref='search' v-model='searchEsp' placeholder= 'Filtrar por...' />
              <table id='table_id'
                    data-toggle='table'
                    class='table table-condensed vue-table'
                    data-search='true'
                    data-filter-control='true'
                    >
                <thead>
                  <tr class="blue">
                      <th>Usuario</th>
                      <th>Bloqueante</th>
                      <th>Q Dias</th>
                      <th>Fecha Desde</th>
                      <th>Fecha Hasta</th>
                      <th>Texto</th>
                      <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>Resumen</strong></td>
                    <td></td>
                    <td><strong>{{ sumEsperas() }}</strong></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr v-for='(acc, index) in filteredEsperas' :key='acc.usrEsperasSttickNroticket'>
                    <td v-html="highlightMatches(acc.usrEsperasUsuario)"></td>
                    <td v-html="highlightMatches(acc.usrEsperasBloqueante)"></td>
                    <td v-html="acc.usrEsperasDias"></td>
                    <td v-html="highlightMatches(acc.usrEsperasFechainicio.replace('T00:00:00Z[-03:00]',''))"></td>
                    <td v-html="highlightMatches(acc.usrEsperasFechafin.substring(0, 4) === '1900' ? null : acc.usrEsperasFechafin.replace('T00:00:00Z[-03:00]', ''))"></td>
                    <td v-html="highlightMatches(acc.usrEsperasObserva)"></td>
                    <td>
                        <el-button type="primary" size="mini" icon="el-icon-search" @click="mostrarEspera(this, this.filteredEsperas, index)" circle/>
                        <el-button type="primary" size="mini" icon="el-icon-edit" @click="mostrarESPE('M', acc)" circle/>
                        <el-button type="primary" size="mini" icon="el-icon-delete" @click="eliminarItem(acc, 'DELETE')" circle/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Minutas" name="8">
            <div class='row'>
              <div class="col-md-10">
              </div>
              <div class="col-md-2">
                <el-button size="mini" type="success" icon="el-icon-plus" @click="mostrarMinuta('A')" plain>nuevo item</el-button>
              </div>
            </div>
            <br/>
            <div id='message'>
              <table id='table_id'
                    data-toggle='table'
                    class='table table-borderless table-condensed vue-table'
                    data-search='true'
                    data-filter-control='true'
                    >
                <thead>
                  <tr class="blue">
                      <th>Fecha</th>
                      <th>Participantes</th>
                      <th>Anotaciones</th>
                      <th>...</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for='acc in ticket.minutas' :key='acc.usrMinutaSttickNroticket'>
                    <td v-html="highlightMatches(acc.usrMinutaFecha)"></td>
                    <td v-html="highlightMatches(acc.usrMinutaParticipantes)"></td>
                    <td>
                      {{acc.usrMinutaDetalle.substring(0,100)}} ...
                    </td>
                    <td>
                    <el-button type="primary" size="mini" icon="el-icon-search" @click="mostrarMinuta('C', acc)" circle/>
                    <el-button type="primary" size="mini" icon="el-icon-edit" @click="mostrarMinuta('M', acc)" circle/>
                    <el-button type="primary" size="mini" icon="el-icon-delete" @click="eliminarItem(acc, 'DELETE')" circle/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Tags" name="9">
            <div class='row'>
              <div class="col-md-10">
                <h4>Etiquetas</h4>
              </div>
              <div class="col-md-2">
                <el-button size="mini" type="success" icon="el-icon-plus" @click="_additemTags('A')" plain>nuevo item</el-button>
              </div>
            </div>
            <br/>
            <div id='message'>
              <table v-if= ticket.tags id='table_id'
                    data-toggle='table'
                    class='table table-borderless table-condensed vue-table'
                    data-search='true'
                    data-filter-control='true'
                    >
                <thead>
                  <tr class="blue">
                      <th>Fecha</th>
                      <th>Profesional</th>
                      <th>Etiqueta</th>
                      <th>...</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <tr v-for='(entity, index) in ticket.tags.sort((a, b) => a.usrTagsFecha > b.usrTagsFecha ? -1 : 1)' :key='entity.usrTagsSttickNroticket'> -->
                  <tr v-for='(entity, index) in ticket.tags' :key='entity.usrTagsSttickNroticket'>
                    <td v-html="entity.usrTagsFecha"></td>
                    <td v-html="highlightMatches(nombreProf(entity.usrTagsProfesional))"></td>
                    <td>
                      {{entity.usrTagsDetalle}}
                    </td>
                    <td>
                    <el-button type="primary" size="mini" icon="el-icon-search" @click="_showDialogTags('C', index)" circle/>
                    <el-button type="primary" size="mini" icon="el-icon-edit" @click="_showDialogTags('M', index)" circle/>
                    <el-button type="primary" size="mini" icon="el-icon-delete" @click="_deleteitemTags(index)" circle/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
        </el-tabs>
    </div>
  </div>
  <el-dialog
    title="Editar ticket"
    v-if="showDialog"
    v-model="showDialog"
    width="90%"
    @close="editedTicket = null"
  >
    <div class="row">
      <div class="col-md-6">
        <label>Titulo</label>
        <el-input v-model="editedTicket.usrSttickDescrip" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <label>Alta</label>
        <el-date-picker v-model="editedTicket.usrSttickFechaAlta" type="date" placeholder="Fecha">
        </el-date-picker>
      </div>
      <div class="col-md-2">
        <label>Fin</label>
        <el-input v-model="editedTicket.usrSttickFechaFin" readonly/>
      </div>
      <div class="col-md-2">
        <label>Empresa</label>
        <el-select v-model="editedTicket.usrSttickEmpresa" filterable clearable>
          <el-option
            v-for="item in empresas"
            :key="item.codigo"
            :label="item.codigo"
            :value="item.codigo"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-md-2">
        <label>Solicitante</label>
        <el-select v-model="editedTicket.usrSttickPropie" filterable clearable placeholder="Propietario">
          <el-option
            v-for="item in usuarios"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-md-2">
        <label>Usuario</label>
        <el-select v-model="editedTicket.usrSttickUsuario" filterable clearable placeholder="Usuario">
          <el-option
            v-for="item in usuarios"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-md-2">
        <label>Recurrente</label>
        <el-select v-model="editedTicket.usrSttickRecurrente" filterable clearable placeholder="Recurrente">
          <el-option
            v-for="item in optRecurrentes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- <div class="row" v-if="getArea.area === 'it'"> -->
    <div class="row">
      <div class="col-md-2">
        <label>Proyecto</label>
        <el-input v-model="editedTicket.usrSttickProyecto"/>
      </div>
      <div class="col-md-2">
        <label>Orden 1</label>
        <el-input v-model="editedTicket.usrSttickOrden1" />
      </div>
      <div class="col-md-2">
        <label>Orden 2</label>
        <el-input v-model="editedTicket.usrSttickOrden2" />
      </div>
      <div class="col-md-2">
        <label>Categoría</label>
        <el-select @change="categoriaValid" v-model="editedTicket.usrSttickCategoria" filterable clearable :disabled="esperaOper==='C'">
          <el-option
            v-for="item in categorias"
            :key="item.usrCatCat"
            :label="item.usrCatDescrp"
            :value="item.usrCatCat"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-md-2">Sub Categoria
        <el-select v-model="editedTicket.usrSttickSubcat" filterable clearable placeholder="Sub cat" :disabled="esperaOper==='C'">
        <el-option
          v-for="item in subCategorias"
          :key="item.usrSubPK.usrSubSubcat"
          :label="item.usrSubPK.usrSubSubcat"
          :value="item.usrSubPK.usrSubSubcat"
        >
        </el-option>
        </el-select>
      </div>
      <div class="col-md-2">
        <label>Prioridad</label>
        <el-input v-model="editedTicket.usrSttickOrdusr" />
      </div>
    </div>
    <div class="dialog-footer">
      <el-button @click="showDialog = false">Cancelar</el-button>
      <el-button type="primary" @click="saveChanges">Guardar</el-button>
    </div>
  </el-dialog>
  <el-dialog
  title="Detalles"
  v-model="dialogVisible"
  width="50%"
>
  <span>
    <div class="row">
    <div class="col-md-5"></div>
    <div class="col-md-2">
      <el-button-group>
        <el-button size="mini" type = "primary" icon="el-icon-arrow-left" @click="mostrarImputacionV3(this, this.filteredImp, this.impIndex - 1)" plain></el-button>
        <el-button size="mini" type = "primary" icon="el-icon-arrow-right" @click="mostrarImputacionV3(this, this.filteredImp, this.impIndex + 1)" plain></el-button>
      </el-button-group>
    </div>
    <div class="col-md-5"></div>
    </div>
    <p p align="justify" style='white-space: pre-line'>
      {{dialogo.substring(0, dialogo.indexOf('TEXTO:')).trim()}}
      <br>
      <br>
      <span style = "background-color: #0d6efd;"><strong> {{dialogo.substring(dialogo.indexOf('TEXTO:') + 7).trim()}} </strong></span>
    </p>
    <div v-if="true">{{this.impIndex + 1}} / {{this.filteredImp.length}}</div>
  </span>
</el-dialog>
<el-dialog
  title="Detalles"
  v-model="dialogEspVisible"
  width="50%"
>
  <span>
    <div class="row">
    <div class="col-md-5"></div>
    <div class="col-md-2">
      <el-button-group>
        <el-button size="mini" type = "primary" icon="el-icon-arrow-left" @click="mostrarEspera(this, this.filteredEsperas, this.espIndex - 1)" plain></el-button>
        <el-button size="mini" type = "primary" icon="el-icon-arrow-right" @click="mostrarEspera(this, this.filteredEsperas, this.espIndex + 1)" plain></el-button>
      </el-button-group>
    </div>
    <div class="col-md-5"></div>
    </div>
    <p p align="justify" style='white-space: pre-line'>
    {{dialogo}}
    </p>
    <div v-if="true">{{this.espIndex + 1}} / {{this.filteredEsperas.length}}</div>
  </span>
</el-dialog>

<el-dialog
  title="Detalles"
  v-if="dialogPendienteVisible"
  v-model="dialogPendienteVisible"
  width="50%"
>
  <span>
    <div class="row">
    <div class="col-md-5"></div>
    <div class="col-md-2">
      <el-button-group>
        <el-button size="mini" type = "primary" icon="el-icon-arrow-left" @click="mostrarPendiente(this, this.fases, this.pendIndex - 1)" plain></el-button>
        <el-button size="mini" type = "primary" icon="el-icon-arrow-right" @click="mostrarPendiente(this, this.fases, this.pendIndex + 1)" plain></el-button>
      </el-button-group>
    </div>
    <div class="col-md-5"></div>
    </div>
    <p p align="justify" style='white-space: pre-line'>
    {{dialogo}}
    </p>
    <div v-if="true">{{this.pendIndex + 1}} / {{this.fases.length}}</div>
  </span>
</el-dialog>
  <el-dialog
    title="Accion"
    v-if="todoVisible"
    v-model="todoVisible"
    width="50%"
    @close='limpiarAccion()'
  >
    <span>
      <div class="row">
        <div class="col-md-4">
          <label v-if='ticket.usrSttickFechaAlta'> Profesional </label>
          <el-select v-model="todo.profesional" filterable clearable placeholder="" :disabled="todoOper==='C'">
            <el-option
              v-for="item in asignados"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-md-8">
          <label v-if='ticket.usrSttickFechaAlta'> Accion </label>
          <el-input v-model="todo.accion" :rows="10" type="textarea" :readonly="todoOper==='C'"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <br/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
        </div>
        <div class="col-md-2">
          <el-button v-if="todoOper==='A'" size="mini" type = "primary" @click="persistirAccion('POST')" >Agregar</el-button>
          <el-button v-if="todoOper==='M'" size="mini" type = "primary" @click="persistirAccion('PUT')" >Modificar</el-button>
        </div>
        <div class="col-md-2">
          <el-button size="mini" type = "primary" @click="limpiarAccion()" >Cancelar</el-button>
        </div>
      </div>
    </span>
  </el-dialog>
  <el-dialog
    title="Imputaciones"
    v-if="imputacionVisible"
    v-model="imputacionVisible"
    width="50%"
    @close='limpiarAccion()'
  >
    <span>
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <el-select v-model="imputacion.profesional" filterable clearable placeholder="Profesional" :disabled="impuOper==='C'">
              <el-option
                v-for="item in asignados"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row">
            <el-date-picker v-model="imputacion.fecha" type="date" placeholder="Fecha" :readonly="impuOper==='C'">
            </el-date-picker>
          </div>
          <div class="row">
            <el-input v-model='imputacion.hs' placeholder='hs consumidas' v-on:keypress="this.isNumber(event)" :readonly="impuOper==='C'"/>
          </div>
        </div>
        <div class="col-md-8">
          <el-input v-model="imputacion.observacion" placeholder='Observaciones' :rows="10" type="textarea" :readonly="impuOper==='C'"/>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-8">
        </div>
        <div class="col-md-2">
          <el-button v-if="impuOper==='A'" size="mini" type = "primary" @click="persistIMPU('POST')" >Agregar</el-button>
          <el-button v-if="impuOper==='M'" size="mini" type = "primary" @click="persistIMPU('PUT')" >Modificar</el-button>
        </div>
        <div class="col-md-2">
          <el-button size="mini" type = "primary" @click="limpiarAccion()" >Cancelar</el-button>
        </div>
      </div>
    </span>
  </el-dialog>
  <!-- minutas -->
  <el-dialog
    title="Minutas"
    v-if="minutaVisible"
    v-model="minutaVisible"
    width="50%"
    @close='limpiarAccion()'
  >
    <span>
      <div class="row">
        <div class="col-md-4">
          <el-input v-model='minuta.participantes' placeholder='Participantes' :readonly="minutaOper==='C'"/>
        </div>
        <div class="col-md-4">
          <el-date-picker v-model="minuta.fecha" type="date" placeholder="Fecha" :readonly="minutaOper==='C'">
          </el-date-picker>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-12">
          <el-input v-model="minuta.detalle" placeholder='Notas' :rows="10" type="textarea" :readonly="minutaOper==='C'"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <br/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
        </div>
        <div class="col-md-2">
          <el-button v-if="minutaOper==='A'" size="mini" type = "primary" @click="persistMINUTA('POST')" >Agregar</el-button>
          <el-button v-if="minutaOper==='M'" size="mini" type = "primary" @click="persistMINUTA('PUT')" >Modificar</el-button>
        </div>
        <div class="col-md-2">
          <el-button size="mini" type = "primary" @click="limpiarAccion()" >Cancelar</el-button>
        </div>
      </div>
    </span>
  </el-dialog>
  <!-- Definicion -->
  <el-dialog
    title="Definicion"
    v-if="definicionVisible"
    v-model="definicionVisible"
    width="100%"
    @close='limpiarAccion()'
  >
    <span>
      <div class="row">
        <div class="col-md-12">
          <el-input v-model="ticket.usrSttickTexto" placeholder='Definicion' :rows="15" type="textarea" :readonly="impuOper==='C'"/>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-8">
        </div>
        <div class="col-md-4">
          <el-button size="mini" type = "primary" @click="this.editedTicket = { ...this.ticket };this.saveChanges()" >Guardar</el-button>
          <el-button size="mini" type = "info" @click="limpiarAccion()" >Cancelar</el-button>
        </div>
      </div>
    </span>
  </el-dialog>
  <!-- Alta / modif esperas -->
  <el-dialog
    title="Esperas"
    v-if="esperaVisible"
    v-model="esperaVisible"
    width="50%"
    @close='limpiarAccion()'
  >
    <span>
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <el-select v-model="espera.usuario" filterable clearable placeholder="Usuario" :disabled="esperaOper==='C'">
              <el-option
                v-for="item in usuarios"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row">
            <el-date-picker v-model="espera.fecha1" type="date" placeholder="Desde" :readonly="esperaOper==='C'">
            </el-date-picker>
          </div>
          <div class="row">
            <el-date-picker v-model="espera.fecha2" type="date" placeholder="Hasta" :readonly="esperaOper==='C'">
            </el-date-picker>
          </div>
        </div>
        <div class="col-md-8">
          <el-input v-model="espera.observacion" placeholder='Observaciones' :rows="10" type="textarea" :readonly="esperaOper==='C'"/>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-8">
        </div>
        <div class="col-md-2">
          <el-button v-if="esperaOper==='A'" size="mini" type = "primary" @click="persistESPERA('POST')" >Agregar</el-button>
          <el-button v-if="esperaOper==='M'" size="mini" type = "primary" @click="persistESPERA('PUT')" >Modificar</el-button>
        </div>
        <div class="col-md-2">
          <el-button size="mini" type = "primary" @click="limpiarAccion()" >Cancelar</el-button>
        </div>
      </div>
    </span>
  </el-dialog>
  <el-dialog
    title="Pendientes"
    v-if="pendienteVisible"
    v-model="pendienteVisible"
    width="50%"
    @close='limpiarAccion()'
  >
    <span>
      <div class="row">
        <div class="col-md-2">
        <el-button-group>
          <el-button size="mini" type = "primary" icon="el-icon-arrow-left" @click="fasesIndex -=1" plain></el-button>
          <el-button size="mini" type = "primary" icon="el-icon-arrow-right" @click="fasesIndex +=1" plain></el-button>
        </el-button-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <el-select v-model="this.editedTicket.fases[fasesIndex].usrFasesProf" filterable clearable placeholder="profesional" :disabled="fasesOperacion==='C'">
              <el-option
                v-for="item in asignados"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row">
            <el-date-picker v-model="this.editedTicket.fases[fasesIndex].usrFasesFechafin" type="date" placeholder="Fecha fin" :readonly="fasesOperacion==='C'">
            </el-date-picker>
          </div>
          <div class="row">
            <el-input v-model='this.editedTicket.fases[fasesIndex].usrFasesHs' placeholder='Horas' v-on:keypress="this.isNumber(event)" :readonly="fasesOperacion==='C'"/>
          </div>
        </div>
        <div class="col-md-8">
          <el-input v-model="this.editedTicket.fases[fasesIndex].usrFasesTareas" placeholder='Observaciones' :rows="10" type="textarea" :readonly="fasesOperacion==='C'"/>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-8">
        </div>
        <div class="col-md-2">
          <el-button v-if = "fasesOperacion ==='M'" size="mini" type = "primary" @click="saveChanges()" >Guardar</el-button>
        </div>
        <div class="col-md-2">
          <el-button size="mini" type = "primary" @click="limpiarAccion()" >Cancelar</el-button>
        </div>
      </div>
    </span>
  </el-dialog>
  <el-dialog
    title="Etiquetas"
    v-if="dialogTagVisible"
    v-model="dialogTagVisible"
    width="50%"
    @close='limpiarAccion()'
  >
    <span>
      <div class="row">
        <div class="col-md-2">
        <el-button-group>
          <el-button size="mini" type = "primary" icon="el-icon-arrow-left" @click="tagsIndex -=1" plain></el-button>
          <el-button size="mini" type = "primary" icon="el-icon-arrow-right" @click="tagsIndex +=1" plain></el-button>
        </el-button-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <el-select v-model="this.editedTicket.tags[tagsIndex].usrTagsProfesional" filterable clearable placeholder="profesional" :disabled="tagsOperacion==='C'">
              <el-option
                v-for="item in asignados"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row">
            <el-date-picker v-model="this.editedTicket.tags[tagsIndex].usrTagsFecha" type="date" placeholder="Fecha" :readonly="tagsOperacion==='C'">
            </el-date-picker>
          </div>
          <div class="row">
            <!-- <el-input v-model='this.editedTicket.tags[tagsIndex].usrTagsProfesional' placeholder='Profesional' v-on:keypress="this.isNumber(event)" :readonly="tagsOperacion==='C'"/> -->
          </div>
        </div>
        <div class="col-md-8">
          <el-select v-model="this.editedTicket.tags[tagsIndex].usrTagsDetalle" filterable clearable placeholder="etiqueta" :disabled="tagsOperacion==='C'">
              <el-option
                v-for="item in tags"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-8">
        </div>
        <div class="col-md-2">
          <el-button v-if = "tagsOperacion ==='M'" size="mini" type = "primary" @click="saveChanges()" >Guardar</el-button>
        </div>
        <div class="col-md-2">
          <el-button size="mini" type = "primary" @click="limpiarAccion()" >Cancelar</el-button>
        </div>
      </div>
    </span>
  </el-dialog>
  <el-dialog
    title="Resueltas"
    v-if="resueltoVisible"
    v-model="resueltoVisible"
    width="50%"
    @close='limpiarAccion()'
  >
    <span>
      <div class="row">
        <div class="col-md-2">
        <el-button-group>
          <el-button size="mini" type = "primary" icon="el-icon-arrow-left" @click="resueltoIndex -=1" plain></el-button>
          <el-button size="mini" type = "primary" icon="el-icon-arrow-right" @click="resueltoIndex +=1" plain></el-button>
        </el-button-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <el-select v-model="this.editedTicket.resueltos[resueltoIndex].usrResueltoProf" filterable clearable placeholder="profesional" :disabled="resueltoOperacion==='C'">
              <el-option
                v-for="item in asignados"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row">
            <el-date-picker v-model="this.editedTicket.resueltos[resueltoIndex].usrResueltoFechafin" type="date" placeholder="Fecha fin" :readonly="resueltoOperacion==='C'">
            </el-date-picker>
          </div>
          <div class="row">
            <el-input v-model='this.editedTicket.resueltos[resueltoIndex].usrResueltoHs' placeholder='Horas' v-on:keypress="this.isNumber(event)" :readonly="resueltoOperacion==='C'"/>
          </div>
        </div>
        <div class="col-md-8">
          <el-input v-model="this.editedTicket.resueltos[resueltoIndex].usrResueltoTareas" placeholder='Observaciones' :rows="10" type="textarea" :readonly="resueltoOperacion==='C'"/>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col-md-8">
        </div>
        <div class="col-md-2">
          <el-button v-if = "resueltoOperacion ==='M'" size="mini" type = "primary" @click="saveChanges()" >Guardar</el-button>
        </div>
        <div class="col-md-2">
          <el-button size="mini" type = "primary" @click="limpiarAccion()" >Cancelar</el-button>
        </div>
      </div>
    </span>
  </el-dialog>
  <el-dialog
      title="Editar ticket"
      v-if="showDialogObserv"
      v-model="showDialogObserv"
      width="90%"
      @close="editedTicket = null"
    >
    <!-- <div class="row" v-if="getArea.area === 'it'"> -->
    <div class="row">
      <div class="row">
        <div class="col-md-12">
          <label>Observacion:</label>
          <el-input v-model="editedTicket.usrSttickTextodesa" :rows="20" type="textarea"/>
        </div>
      </div>
    </div>
    <div class="dialog-footer">
      <el-button @click="showDialogObserv = false">Cancelar</el-button>
      <el-button type="primary" @click="saveChanges">Guardar</el-button>
    </div>
  </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import config from '../../public/config.json'
import router from '../router'
import shared from '../shared/shared'
import back from '../components/back.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
export default {
  extends: shared,
  name: 'imp-list-row',
  components: {
    back
  },
  beforeMount () {
    document.title = this.$route.params.id
    if (this.isLoggedIn) {
      this.getProfesionals(this)
      this.getUsers()
    }
  },
  mounted: function () {
    if (!this.isLoggedIn) {
      router.push('/')
    }
    this.getTicket()
    this.getDetalleHs()
  },
  data: function () {
    return {
      asignados: [],
      empresas: [],
      showDialogObserv: false,
      tagsIndex: 0,
      dialogTagVisible: false,
      tags: [{
        value: 'TOP5',
        label: 'TOP5'
      }, {
        value: 'TOP10',
        label: 'TOP10'
      }, {
        value: 'P1',
        label: '#1'
      }, {
        value: 'P2',
        label: '#2'
      }, {
        value: 'P3',
        label: '#3'
      }, {
        value: 'P4',
        label: '#4'
      }, {
        value: 'P5',
        label: '#5'
      }],
      categorias: [],
      subCategorias: [],
      optRecurrentes: [{
        value: 'S',
        label: 'Si'
      }, {
        value: 'N',
        label: 'No'
      }
      ],
      resueltoVisible: false,
      resueltoOperacion: '',
      resueltoIndex: 0,
      fasesOperacion: '',
      tagsOperacion: '',
      fasesIndex: 0,
      showDialog: false,
      editedTicket: null,
      pageTitle: 'Título por defecto',
      impIndex: 0,
      espIndex: 0,
      pendIndex: 0,
      detallehs: [],
      isLoaded: false,
      chartOptions_p: [],
      dataZonas_p: [],
      todoOper: '',
      impuOper: '',
      esperaOper: '',
      minutaOper: '',
      minutaVisible: false,
      minuta: [{
        item: 0,
        participantes: '',
        fecha: '',
        detalle: ''
      }],
      tituloAccion: 'Nueva',
      dialogo: '',
      dialogVisible: false,
      dialogEspVisible: false,
      dialogPendienteVisible: false,
      todoVisible: false,
      imputacionVisible: false,
      esperaVisible: false,
      pendienteVisible: false,
      definicionVisible: false,
      activeName: '3',
      activeHeaderName: '2',
      message: 'Imputaciones List Row',
      search: '',
      searchEsp: '',
      ticket: [],
      imputaciones: [],
      // profesionales: [],
      usuarios: [],
      acciones: [],
      accion: [],
      fases: [],
      esperas: [],
      avance: 50,
      esperaRsm: '',
      todo: [{
        item: 0,
        profesional: '',
        accion: ''
      }],
      imputacion: [{
        item: 0,
        profesional: '',
        fecha: '',
        observacion: '',
        hs: 0.00
      }],
      espera: [{
        item: 0,
        usuario: '',
        fecha1: '',
        fecha2: '',
        observacion: ''
      }],
      pendiente: [{
        item: 0,
        profesional: '',
        tarea: '',
        horas: '',
        observacion: ''
      }]
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['getArea']),
    ...mapGetters(['isLoggedIn']),
    filteredDetHs () {
      return this.detallehs
        .filter(this.filterByProfDetHs)
    },
    filteredImp () {
      return this.imputaciones
        .filter(this.filterByProf)
        .sort((a, b) => a.usrImpHsiImpHsFch > b.usrImpHsiImpHsFch ? -1 : 1)
    },
    filteredEsperas () {
      return this.esperas.filter(this.filteredByUsr)
    },
    hsByProf () {
      var profs = []
      this.filteredImp.forEach(function (x) {
        var exists = false
        profs.forEach(function (y) {
          if (x.usrImpHsiImpHsProf === y.name) {
            exists = true
            y.value = y.value + x.usrImpHsiHs
          }
        })
        if (!exists) {
          profs.push({ name: x.usrImpHsiImpHsProf, value: x.usrImpHsiHs })
        }
      })
      return profs
    }
  },
  methods: {
    nombreProf (codigo) {
      var retorno = ''
      this.asignados.forEach(function (item) {
        if (codigo === item.value) {
          retorno = item.label
        }
      })
      return retorno
    },
    async categoriaValid () {
      this.editedTicket.usrSttickSubcat = ''
      await this.getSubCategorias(this, this.editedTicket.usrSttickCategoria)
      this.subCategorias = JSON.parse(localStorage.getItem('subCategorias'))
    },
    genUrl () {
      if (this.ticket.usrSttickNroticket) {
        const el = document.createElement('textarea')
        el.value = 'http://ticket.grupopenna.com.ar/?ticket=' + this.ticket.usrSttickNroticket
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
      }
    },
    saveChanges () {
      var pUrl = 'http://' + config.ip + '/ticket/Sttick'
      this.editedTicket.usrSttickFchord = this.editedTicket.usrSttickFchord.indexOf('T') > -1 ? this.editedTicket.usrSttickFchord : this.editedTicket.usrSttickFchord.concat('T00:00:00Z[-03:00]')
      this.editedTicket.usrStFecalt = this.editedTicket.usrStFecalt.indexOf('T') > -1 ? this.editedTicket.usrStFecalt : this.editedTicket.usrStFecalt.concat('T00:00:00Z[-03:00]')
      if (this.editedTicket.usrSttickFechaAlta instanceof Date) {
        this.editedTicket.usrSttickFechaAlta = this.editedTicket.usrSttickFechaAlta.toISOString().substring(0, 10)
      }
      this.editedTicket.usrSttickFechaAlta = this.editedTicket.usrSttickFechaAlta.substring(0, 10).concat('T00:00:00Z[-03:00]')

      this.editedTicket.usrSttickFechaentrega = this.editedTicket.usrSttickFechaentrega.replace('T00:00:00Z[-03:00]', '').concat('T00:00:00Z[-03:00]')
      this.editedTicket.usrSttickFechaFin = this.editedTicket.usrSttickFechaFin.indexOf('T') > -1 ? this.editedTicket.usrSttickFechaFin : this.editedTicket.usrSttickFechaFin.concat('T00:00:00Z[-03:00]')
      this.editedTicket.fases.forEach(function (item) {
        if (!item.usrFasesFechainicio) {
          item.usrFasesFechainicio = '1900-01-01T00:00:00Z[-03:00]'
        }
        if (!item.usrFasesFechafin) {
          item.usrFasesFechafin = '1900-01-01T00:00:00Z[-03:00]'
        }
        if (item.usrFasesFechainicio instanceof Date) {
          item.usrFasesFechainicio = item.usrFasesFechainicio.toISOString().substring(0, 10)
        }
        if (item.usrFasesFechafin instanceof Date) {
          item.usrFasesFechafin = item.usrFasesFechafin.toISOString().substring(0, 10)
        }
        item.usrFasesFechainicio = item.usrFasesFechainicio.substring(0, 10).concat('T00:00:00Z[-03:00]')
        item.usrFasesFechafin = item.usrFasesFechafin.substring(0, 10).concat('T00:00:00Z[-03:00]')
      })
      this.editedTicket.resueltos.forEach(function (item) {
        if (!item.usrResueltoFechainicio) {
          item.usrResueltoFechainicio = '1900-01-01T00:00:00Z[-03:00]'
        }
        if (!item.usrResueltoFechafin) {
          item.usrResueltoFechafin = '1900-01-01T00:00:00Z[-03:00]'
        }
        if (item.usrResueltoFechainicio instanceof Date) {
          item.usrResueltoFechainicio = item.usrResueltoFechainicio.toISOString().substring(0, 10)
        }
        if (item.usrResueltoFechafin instanceof Date) {
          item.usrResueltoFechafin = item.usrResueltoFechafin.toISOString().substring(0, 10)
        }
        item.usrResueltoFechainicio = item.usrResueltoFechainicio.substring(0, 10).concat('T00:00:00Z[-03:00]')
        item.usrResueltoFechafin = item.usrResueltoFechafin.substring(0, 10).concat('T00:00:00Z[-03:00]')
      })
      this.editedTicket.esperas.forEach(function (item) {
        item.usrEsperasFchrecl = item.usrEsperasFchrecl || '1900-01-01'
        item.usrEsperasFechainicio = item.usrEsperasFechainicio || '1900-01-01'
        item.usrEsperasFechafin = item.usrEsperasFechafin || '1900-01-01'
        item.usrEsperasFchrecl = item.usrEsperasFchrecl.substring(0, 10).concat('T00:00:00Z[-03:00]')
        item.usrEsperasFechainicio = item.usrEsperasFechainicio.substring(0, 10).concat('T00:00:00Z[-03:00]')
        item.usrEsperasFechafin = item.usrEsperasFechafin.substring(0, 10).concat('T00:00:00Z[-03:00]')
      })
      this.editedTicket.tags.forEach(function (item) {
        if (!item.usrTagsFecha) {
          item.usrTagsFecha = '1900-01-01T00:00:00Z[-03:00]'
        }
        if (item.usrTagsFecha instanceof Date) {
          item.usrTagsFecha = item.usrTagsFecha.toISOString().substring(0, 10)
        }
        item.usrTagsFecha = item.usrTagsFecha.substring(0, 10).concat('T00:00:00Z[-03:00]')
      })
      this.editedTicket.minutas.forEach(function (item) {
        item.usrMinutaFecha = item.usrMinutaFecha.indexOf('T') > -1 ? item.usrMinutaFecha : item.usrMinutaFecha.concat('T00:00:00Z[-03:00]')
      })
      this.editedTicket.imputaciones.forEach(function (item) {
        item.usrImpHsiImpHsFch = item.usrImpHsiImpHsFch.indexOf('T') > -1 ? item.usrImpHsiImpHsFch : item.usrImpHsiImpHsFch.concat('T00:00:00Z[-03:00]')
      })
      var pBody = JSON.stringify(this.editedTicket)
      this.persist(pUrl, 'PUT', pBody)
    },
    async _showDialog () {
      this.editedTicket = { ...this.ticket }
      await this.getEmpresas(this)
      await this.getCategorias(this)
      await this.getSubCategorias(this, this.editedTicket.usrSttickCategoria)
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.categorias = JSON.parse(localStorage.getItem('categorias'))
      this.subCategorias = JSON.parse(localStorage.getItem('subCategorias'))
      this.showDialog = true
    },
    _showDialogObserv () {
      this.editedTicket = { ...this.ticket }
      this.showDialogObserv = true
    },
    async _additemTags () {
      const self = this
      // obtiene el nro de item maximo
      var _nroItem = 0
      this.ticket.tags.forEach(function (x) {
        if (_nroItem < x.usrTagsPK.usrTagsNroitem) {
          _nroItem = x.usrTagsPK.usrTagsNroitem
        }
      }
      )
      const today = new Date(new Date().toDateString())
      let profesional = ''
      await self.getProfesionals(self)
      self.asignados.forEach(function (element) {
        if (self.getToken.user === element.usrlogin) {
          profesional = element.value
        }
      })
      var obj =
        {
          usrTagsPK: { usrTagsSttickNroticket: this.ticket.usrSttickNroticket, usrTagsNroitem: _nroItem + 1 },
          usrTagsFecha: today,
          usrTagsProfesional: profesional,
          usrTagsDetalle: null,
          usrTaFecalt: null,
          usrTaFecmod: null,
          usrTaUserid: 'ADMIN',
          usrTaUltopr: 'A',
          usrTaDebaja: 'N',
          usrTaHormov: null,
          usrTaModule: null,
          usrTaOalias: 'USR_TAGS',
          usrTaLottra: null,
          usrTaLotrec: null,
          usrTaLotori: null,
          usrTaSysver: null,
          usrTaCmpver: null
        }
      this.ticket.tags.push(obj)
      this._showDialogTags('M', this.ticket.tags.length - 1)
    },
    _additemTareas () {
      // obtiene el nro de item maximo
      var _nroItem = 0
      this.ticket.fases.forEach(function (x) {
        if (_nroItem < x.usrFasesPK.usrFasesNroitem) {
          _nroItem = x.usrFasesPK.usrFasesNroitem
        }
      }
      )
      var obj =
        {
          usrFasesPK: { usrFasesSttickNroticket: this.ticket.usrSttickNroticket, usrFasesNroitem: _nroItem + 1 },
          usrFasesCodfase: null,
          usrFasesFechainicio: null,
          usrFasesFechafin: null,
          usrFasesProf: '',
          usrFasesObserva: '',
          usrFasesTareas: '',
          usrFasesVercon1: null,
          usrFasesVercon2: null,
          usrFaFecalt: null,
          usrFaFecmod: null,
          usrFaUserid: 'ADMIN',
          usrFaUltopr: 'A',
          usrFaDebaja: 'N',
          usrFaHormov: null,
          usrFaModule: null,
          usrFaOalias: 'USR_FASES',
          usrFaLottra: null,
          usrFaLotrec: null,
          usrFaLotori: null,
          usrFaSysver: null,
          usrFaCmpver: null,
          usrFasesHs: 0
        }
      this.ticket.fases.push(obj)
      this._showDialogTareas('M', this.ticket.fases.length - 1)
    },
    _additemResuelto () {
      // obtiene el nro de item maximo
      var _nroItem = 0
      this.ticket.resueltos.forEach(function (x) {
        if (_nroItem < x.usrResueltoPK.usrResueltoNroitem) {
          _nroItem = x.usrResueltoPK.usrResueltoNroitem
        }
      }
      )
      var obj =
        {
          usrResueltoPK: { usrResueltoSttickNroticket: this.ticket.usrSttickNroticket, usrResueltoNroitem: _nroItem + 1 },
          usrResueltoCodfase: null,
          usrResueltoFechainicio: null,
          usrResueltoFechafin: null,
          usrResueltoProf: '',
          usrResueltoObserva: '',
          usrResueltoTareas: '',
          usrResueltoVercon1: null,
          usrResueltoVercon2: null,
          usrReFecalt: null,
          usrReFecmod: null,
          usrReUserid: 'ADMIN',
          usrReUltopr: 'A',
          usrReDebaja: 'N',
          usrReHormov: null,
          usrReModule: null,
          usrReOalias: 'USR_RESUEL',
          usrReLottra: null,
          usrReLotrec: null,
          usrReLotori: null,
          usrReSysver: null,
          usrReCmpver: null,
          usrResueltoHs: 0
        }
      this.ticket.resueltos.push(obj)
    },
    _deleteitemTags (index) {
      ElMessageBox.confirm(
        'se va a eliminar un item, desea continuar?',
        'Atencion',
        {
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          type: 'warning'
        }
      )
        .then(() => {
          this.editedTicket = { ...this.ticket }
          this.editedTicket.tags.splice(index, 1)
          this.saveChanges()
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: 'Operacion Cancelada'
          })
        })
    },
    _deleteitemTareas (index) {
      ElMessageBox.confirm(
        'se va a eliminar un item, desea continuar?',
        'Atencion',
        {
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          type: 'warning'
        }
      )
        .then(() => {
          this.editedTicket = { ...this.ticket }
          this.editedTicket.fases.splice(index, 1)
          this.saveChanges()
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: 'Operacion Cancelada'
          })
        })
    },
    _deleteitemResuelto (index) {
      ElMessageBox.confirm(
        'se va a eliminar un item, desea continuar?',
        'Atencion',
        {
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          type: 'warning'
        }
      )
        .then(() => {
          this.editedTicket = { ...this.ticket }
          this.editedTicket.resueltos.splice(index, 1)
          this.saveChanges()
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: 'Operacion Cancelada'
          })
        })
    },
    async _showDialogTags (operacion, index) {
      const self = this
      self.editedTicket = { ...self.ticket }
      self.tagsIndex = index
      self.dialogTagVisible = true
      self.tagsOperacion = operacion
      // if (operacion === 'A') {
      //   self.editedTicket.tags[self.tagsIndex].usrTagsFecha = new Date(new Date().toDateString())
      //   await self.getProfesionals(self)
      //   self.asignados.forEach(function (element) {
      //     if (self.getToken.user === element.usrlogin) {
      //       self.ticket.editedTicket[self.tagsIndex].usrTagsProfesional = element.value
      //     }
      //   })
      // }
    },
    _showDialogTareas (operacion, index) {
      this.editedTicket = { ...this.ticket }
      this.editedTicket.fases[index].usrFasesFechafin = this.editedTicket.fases[index].usrFasesFechafin === '1900-01-01' ? null : this.editedTicket.fases[index].usrFasesFechafin
      this.fasesIndex = index
      this.pendienteVisible = true
      this.fasesOperacion = operacion
    },
    _showDialogResuelto (operacion, index) {
      this.editedTicket = { ...this.ticket }
      this.resueltoIndex = index
      this.resueltoVisible = true
      this.resueltoOperacion = operacion
    },
    copyText () {
      const el = document.createElement('textarea')
      el.value = this.ticket.usrSttickTextodesa
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    focusImpu () {
      this.$refs.search.focus()
    },
    updateDetalleHs () {
    },
    updateChart () {
      var xZonaP = []
      var yZonaP = []
      this.datos = [...this.filteredImp]
      this.datos.sort((a, b) => a.usrImpHsiImpHsFch < b.usrImpHsiImpHsFch ? -1 : 1)
      this.datos.forEach(function (x) {
        console.log('#' + x.usrImpHsiImpHsFch.split('-')[0] + '-' + x.usrImpHsiImpHsFch.split('-')[1])
        var exists = false
        var item = 0
        xZonaP.forEach(function (y) {
          if (x.usrImpHsiImpHsFch.split('-')[0] + '-' + x.usrImpHsiImpHsFch.split('-')[1] === y) {
            exists = true
            yZonaP[item] = Math.round((yZonaP[item] + x.usrImpHsiHs) * 100) / 100
          }
          item++
        })
        if (!exists) {
          xZonaP.push(x.usrImpHsiImpHsFch.split('-')[0] + '-' + x.usrImpHsiImpHsFch.split('-')[1])
          yZonaP.push(Math.round((x.usrImpHsiHs) * 100) / 100)
        }
      })
      this.dataZonas_p = [
        {
          data: yZonaP,
          type: 'bar',
          name: 'horas'
        }
      ]
      this.chartOptions_p = {
        colors: ['#00adff'],
        xaxis: {
          categories: xZonaP,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5
          },
          labels: {
            offsetY: -5,
            rotate: 0,
            style: {
              colors: '#bbbfc0',
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 420
            }
          }
        },
        dataLabels: {
          offsetY: 15,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.9
          },
          style: {
            colors: ['white', 'white'],
            fontSize: '15px',
            fontWeight: 640
          }
        },
        legend: {
          show: true,
          labels: {
            useSeriesColors: true
          }
        }
      }
    },
    getFromCache () {
      if (localStorage.getItem('imputaciones')) {
        try {
          var self = this
          self.imputaciones = JSON.parse(localStorage.getItem('imputaciones'))
          self.acciones = JSON.parse(localStorage.getItem('acciones'))
          self.ticket = JSON.parse(localStorage.getItem('ticket'))
          self.fases = JSON.parse(localStorage.getItem('fases'))
          self.esperas = JSON.parse(localStorage.getItem('esperas'))
          this.closeFSL(this)
        } catch (e) {
          localStorage.removeItem('imputaciones')
          this.closeFSL(this)
        }
      }
    },
    eliminarItem (val, method) {
      if (method === 'DELETE') {
        ElMessageBox.confirm(
          'se va a eliminar un item, desea continuar?',
          'Atencion',
          {
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            type: 'warning'
          }
        )
          .then(() => {
            if (val.usrAccionesPK) {
              this.todo.item = val.usrAccionesPK.usrAccionesItem
              this.deleteAccion()
            }
            if (val.usrImpHsiPK) {
              this.imputacion.item = val.usrImpHsiPK.usrImpHsiNroitm
              this.deleteImputacion()
            }
            if (val.usrMinutaPK) {
              this.minuta.item = val.usrMinutaPK.usrMinutaNroitem
              this.deleteMinuta()
            }
            if (val.usrEsperasPK) {
              this.espera.item = val.usrEsperasPK.usrEsperasNroitm
              this.deleteEspera()
            }
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: 'Operacion Cancelada'
            })
          })
      }
    },
    deleteAccion () {
      this.openFSL()
      axios.delete('http://' + config.ip + '/ticket/accion/params;ticket=' + this.ticket.usrSttickNroticket + ';item=' + this.todo.item, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.getToken.token
        }
      })
        .then(response => {
          this.limpiarAccion()
          this.getTicket()
          this.successMsg('Operacion exitosa')
          this.closeFSL()
        })
        .catch(error => {
          console.error('Error al grabar: ', error)
          this.errorMsg('Error al grabar')
          this.closeFSL()
        })
    },
    persistIMPU (pMethod) {
      var pBody = JSON.stringify({
        usrImpHsiPK: { usrImpHsiTick: this.ticket.usrSttickNroticket, usrImpHsiNroitm: this.imputacion.item },
        usrImpHsiImpHsProf: this.imputacion.profesional,
        usrImpHsiImpHsFch: pMethod === 'PUT' ? this.imputacion.fecha.concat('T00:00:00Z[-03:00]') : this.imputacion.fecha,
        usrImpHsiObs: this.imputacion.observacion,
        usrImpHsiHs: this.imputacion.hs

      })
      var pUrl = 'http://' + config.ip + '/ticket/imputacion'
      this.persist(pUrl, pMethod, pBody)
    },
    mostrarPEND (operacion, val) {
      var self = this
      self.pendienteVisible = true
      self.pendiente.item = 0
      self.pendOper = operacion
      // var today = new Date()
      // today = new Date(today.toDateString())
      // self.pendiente.fecha1 = today
      if (val) {
        self.espera.profesional = val.usrFasesProf
        self.espera.tarea = val.usrFasesTareas
        self.espera.horas = val.usrFasesHs
        self.espera.observacion = val.usrFasesObserva
      }
    },
    mostrarESPE (operacion, val) {
      var self = this
      self.esperaVisible = true
      self.espera.item = 0
      self.esperaOper = operacion // ver usos
      var today = new Date()
      today = new Date(today.toDateString())
      self.espera.fecha1 = today
      self.espera.fecha2 = null
      if (val) {
        self.espera.fecha1 = val.usrEsperasFechainicio.substring(0, 4) === '1900' ? null : val.usrEsperasFechainicio.replace('T00:00:00Z[-03:00]', '')
        self.espera.fecha2 = val.usrEsperasFechafin.substring(0, 4) === '1900' ? null : val.usrEsperasFechafin.replace('T00:00:00Z[-03:00]', '')
        self.espera.item = val.usrEsperasPK.usrEsperasNroitm
        self.espera.usuario = val.usrEsperasUsuario
        self.espera.observacion = val.usrEsperasObserva
      }
    },
    persistESPERA (pMethod) {
      this.espera.fecha1 = this.espera.fecha1 || '1900-01-01T00:00:00Z[-03:00]'
      this.espera.fecha2 = this.espera.fecha2 || '1900-01-01T00:00:00Z[-03:00]'
      if (this.espera.fecha1 instanceof Date) {
        this.espera.fecha1 = this.espera.fecha1.toISOString().substring(0, 10)
      }
      if (this.espera.fecha2 instanceof Date) {
        this.espera.fecha2 = this.espera.fecha2.toISOString().substring(0, 10)
      }
      var pBody = JSON.stringify({
        usrEsperasPK: { usrEsperasSttickNroticket: this.ticket.usrSttickNroticket, usrEsperasNroitm: this.espera.item },
        usrEsperasUsuario: this.espera.usuario,
        usrEsperasFechainicio: this.espera.fecha1.replace('T00:00:00Z[-03:00]', '').concat('T00:00:00Z[-03:00]'),
        usrEsperasFechafin: this.espera.fecha2.replace('T00:00:00Z[-03:00]', '').concat('T00:00:00Z[-03:00]'),
        usrEsperasObserva: this.espera.observacion,
        usrEsperasBloqueante: 'S',
        usrEsperasNotifi: 'N',
        usrEsperasNotenv: 'N',
        usrEsperasFchrecl: '1900-01-01T00:00:00Z[-03:00]'
      })
      var pUrl = 'http://' + config.ip + '/ticket/espera'
      this.persist(pUrl, pMethod, pBody)
    },
    persistDEFINICION (pMethod) {
      var pBody = ''
      var pUrl = 'http://' + config.ip + '/ticket/Sttick/specific/params;ticket=' + this.ticket.usrSttickNroticket + ';descripcion=' + encodeURIComponent(this.ticket.usrSttickTexto)
      this.persist(pUrl, pMethod, pBody)
    },
    persistMINUTA (pMethod) {
      var pBody = JSON.stringify({
        usrMinutaPK: { usrMinutaSttickNroticket: this.ticket.usrSttickNroticket, usrMinutaNroitem: this.minuta.item },
        usrMinutaParticipantes: this.minuta.participantes,
        usrMinutaFecha: pMethod === 'PUT' ? this.minuta.fecha.concat('T00:00:00Z[-03:00]') : this.minuta.fecha,
        usrMinutaDetalle: this.minuta.detalle
      })
      var pUrl = 'http://' + config.ip + '/ticket/minuta'
      this.persist(pUrl, pMethod, pBody)
    },
    async persistirAccion (pMethod) {
      var pBody = JSON.stringify({
        usrAccionesPK: { usrAccionesSttickNroticket: this.ticket.usrSttickNroticket, usrAccionesItem: this.todo.item },
        usrAccionesAccion: this.todo.accion,
        usrAccionesProf: this.todo.profesional
      })
      var pUrl = 'http://' + config.ip + '/ticket/accion'
      this.persist(pUrl, pMethod, pBody)
    },
    async persist (pUrl, pMethod, pBody) {
      this.openFSL()
      fetch(pUrl, {
        method: pMethod,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.getToken.token
        },
        body: pBody
      })
        .then(async response => {
          if (response.status === 401) {
            this.errorMsg('permisos insuficientes para completar la operacion')
          } else {
            this.limpiarAccion()
            this.getTicket()
            this.getDetalleHs()
            this.successMsg('Operacion exitosa')
          }
          this.closeFSL()
        })
        .catch(error => {
          console.error('Error al grabar: ', error)
          this.errorMsg('Error al grabar')
          this.closeFSL()
        })
    },
    deleteImputacion () {
      this.openFSL()
      axios.delete('http://' + config.ip + '/ticket/imputacion/params;ticket=' + this.ticket.usrSttickNroticket + ';item=' + this.imputacion.item, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.getToken.token
        }
      })
        .then(response => {
          this.limpiarAccion()
          this.getTicket()
          this.successMsg('Operacion exitosa')
          this.closeFSL()
        })
        .catch(error => {
          console.error('Error al grabar: ', error)
          this.errorMsg('Error al grabar')
          this.closeFSL()
        })
    },
    deleteMinuta () {
      this.openFSL()
      axios.delete('http://' + config.ip + '/ticket/minuta/minuta;usrMinutaSttickNroticket=' + this.ticket.usrSttickNroticket + ';usrMinutaNroitem=' + this.minuta.item, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.getToken.token
        }
      })
        .then(response => {
          this.limpiarAccion()
          this.getTicket()
          this.successMsg('Operacion exitosa')
          this.closeFSL()
        })
        .catch(error => {
          console.error('Error al grabar: ', error)
          this.errorMsg('Error al grabar')
          this.closeFSL()
        })
    },
    deleteEspera () {
      this.openFSL()
      axios.delete('http://' + config.ip + '/ticket/espera/params;ticket=' + this.ticket.usrSttickNroticket + ';item=' + this.espera.item, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.getToken.token
        }
      })
        .then(response => {
          this.limpiarAccion()
          this.getTicket()
          this.successMsg('Operacion exitosa')
          this.closeFSL()
        })
        .catch(error => {
          console.error('Error al grabar: ', error)
          this.errorMsg('Error al grabar')
          this.closeFSL()
        })
    },
    limpiarAccion () {
      var self = this
      self.todo.profesional = ''
      self.todo.accion = ''
      self.todoVisible = false
      self.imputacion.item = 0
      self.imputacion.profesional = ''
      self.imputacion.fecha = ''
      self.imputacion.observacion = ''
      self.imputacion.hs = 0.00
      self.imputacionVisible = false
      self.espera.item = 0
      self.espera.usuario = ''
      self.espera.fecha1 = ''
      self.espera.fecha2 = ''
      self.espera.observacion = ''
      self.esperaVisible = false
      self.pendienteVisible = false
      self.resueltoVisible = false
      self.definicionVisible = false
      self.pendiente.item = 0
      self.pendiente.profesional = ''
      self.pendiente.hs = 0.00
      self.pendiente.observacion = ''
      self.minuta.item = 0
      self.minuta.participantes = ''
      self.minuta.fecha = ''
      self.minuta.detalle = ''
      self.minutaVisible = false
      self.showDialog = false
      self.dialogTagVisible = false
      self.showDialogObserv = false
    },
    limpiarImputacion () {
      var self = this
      self.todo.profesional = ''
      self.todo.accion = ''
      self.imputacionVisible = false
    },
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL () {
      this.loading.close()
    },
    getUsers: function (e) {
      var self = this
      self.openFSL()
      const url = 'http://' + config.ip + '/ticket/protick/'
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('usuarios', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.usuarios = JSON.parse(localStorage.getItem('usuarios'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    mostrarImputacionV3 (self, item, index) {
      console.log('checkpoint')
      var tkt = self.ticket.usrSttickNroticket + ' ' + self.ticket.usrSttickDescrip
      var fecha = item[index].usrImpHsiImpHsFch.replace('T00:00:00Z[-03:00]', '')
      var profesional = item[index].usrImpHsiImpHsProf
      var observacion = item[index].usrImpHsiObs
      var hs = item[index].usrImpHsiHs
      self.impIndex = index
      self.dialogVisible = true
      self.dialogo = 'TICKET:\n' + tkt + '\n\n'
      self.dialogo = self.dialogo + 'FECHA:\n' + fecha + '\n\n'
      self.dialogo = self.dialogo + 'PROFESIONAL:\n' + profesional + '\n\n'
      self.dialogo = self.dialogo + 'HS:\n' + hs + '\n\n'
      self.dialogo = self.dialogo + 'TEXTO:\n' + observacion
    },
    mostrarTODO (operacion, val) {
      var self = this
      self.todoVisible = true
      self.todo.item = 0
      self.todoOper = operacion
      if (val) {
        self.todo.item = val.usrAccionesPK.usrAccionesItem
        self.todo.profesional = val.usrAccionesProf
        self.todo.accion = val.usrAccionesAccion
      }
    },
    mostrarDEFINICION (operacion, val) {
      var self = this
      self.definicionVisible = true
      // self.imputacion.item = 0
      // self.impuOper = operacion
      // var today = new Date()
      // today = new Date(today.toDateString())
      // self.imputacion.fecha = today
      // if (val) {
      //   self.imputacion.fecha = val.usrImpHsiImpHsFch.replace('T00:00:00Z[-03:00]', '')
      //   self.imputacion.item = val.usrImpHsiPK.usrImpHsiNroitm
      //   self.imputacion.profesional = val.usrImpHsiImpHsProf
      //   self.imputacion.observacion = val.usrImpHsiObs
      //   self.imputacion.hs = val.usrImpHsiHs
      // }
    },
    async mostrarIMPU (operacion, val) {
      var self = this
      self.imputacionVisible = true
      self.imputacion.item = 0
      self.impuOper = operacion
      // var today = new Date()
      var today = new Date(new Date().toDateString())
      self.imputacion.fecha = today
      await this.getProfesionals(self)
      self.asignados.forEach(function (element) {
        if (self.getToken.user === element.usrlogin) {
          self.imputacion.profesional = element.value
        }
      })
      if (val) {
        self.imputacion.fecha = val.usrImpHsiImpHsFch.replace('T00:00:00Z[-03:00]', '')
        self.imputacion.item = val.usrImpHsiPK.usrImpHsiNroitm
        self.imputacion.profesional = val.usrImpHsiImpHsProf
        self.imputacion.observacion = val.usrImpHsiObs
        self.imputacion.hs = val.usrImpHsiHs
      }
    },
    mostrarEspera (self, item, index) {
      var tkt = self.ticket.usrSttickNroticket + ' ' + self.ticket.usrSttickDescrip
      var fecha1 = item[index].usrEsperasFechainicio.replace('T00:00:00Z[-03:00]', '')
      var fecha2 = item[index].usrEsperasFechafin.replace('T00:00:00Z[-03:00]', '')
      var usuario = item[index].usrEsperasUsuario
      var observacion = item[index].usrEsperasObserva
      self.espIndex = index
      self.dialogEspVisible = true
      self.dialogo = 'TICKET:\n' + tkt + '\n\n'
      self.dialogo = self.dialogo + 'INICIO:\n' + fecha1 + '\n\n'
      self.dialogo = self.dialogo + 'FIN:\n' + fecha2 + '\n\n'
      self.dialogo = self.dialogo + 'USUARIO:\n' + usuario + '\n\n'
      self.dialogo = self.dialogo + 'TEXTO:\n' + observacion
    },
    mostrarPendiente (self, item, index) {
      console.log('checkpoint')
      var tkt = self.ticket.usrSttickNroticket + ' ' + self.ticket.usrSttickDescrip
      var profesional = item[index].usrFasesProf
      var tarea = item[index].usrFasesTareas
      var hs = item[index].usrFasesHs
      var observacion = item[index].usrFasesObserva
      self.pendIndex = index
      self.dialogPendienteVisible = true
      self.dialogo = 'TICKET:\n' + tkt + '\n\n'
      self.dialogo = self.dialogo + 'PROFESIONAL:\n' + profesional + '\n\n'
      self.dialogo = self.dialogo + 'FASE/TAREA:\n' + tarea + '\n\n'
      self.dialogo = self.dialogo + 'HS ESTIMADAS:\n' + hs + '\n\n'
      self.dialogo = self.dialogo + 'OBSERVACION:\n' + observacion
    },
    mostrarMinuta (operacion, val) {
      var self = this
      self.minutaVisible = true
      self.minuta.item = 0
      self.minutaOper = operacion
      var today = new Date()
      today = new Date(today.toDateString())
      self.minuta.fecha = today
      if (val) {
        self.minuta.item = val.usrMinutaPK.usrMinutaNroitem
        self.minuta.participantes = val.usrMinutaParticipantes
        self.minuta.fecha = val.usrMinutaFecha
        self.minuta.detalle = val.usrMinutaDetalle
      }
    },
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    sumPend () {
      var res = 0
      this.fases.forEach(function (item) {
        res = res + item.usrFasesHs
      }
      )
      return Math.round(res * 100) / 100
    },
    sumResuelto () {
      if (!this.ticket.resueltos) return 0
      var res = 0
      this.ticket.resueltos.forEach(function (item) {
        res = res + item.usrResueltoHs
      }
      )
      return Math.round(res * 100) / 100
    },
    sumSaldo () {
      var res = 0
      this.filteredDetHs.forEach(function (item) {
        res += item.saldo
      })
      // res = this.filteredDetHs.reduce((acc, prof) => acc + prof.saldo, 0)
      // if (res > 0) {
      //   res = Math.round(res * 100) / 100
      // }
      return res
    },
    sumImp () {
      var res = 0
      this.filteredImp.forEach(function (item) {
        res = res + item.usrImpHsiHs
      }
      )
      return Math.round(res * 100) / 100
    },
    sum () {
      var res = 0
      this.filteredDetHs.forEach(function (item) {
        res = res + item.imputado
      }
      )
      return Math.round(res * 100) / 100
    },
    sumEsperas () {
      var res = 0
      this.filteredEsperas.forEach(function (item) {
        res = res + item.usrEsperasDias
      }
      )
      return Math.round(res * 100) / 100
    },
    resEsperas () {
      var res = ''
      this.filteredEsperas.forEach(function (item) {
        if (item.usrEsperasFechafin.substring(0, 4) === '1900') {
          res = res + 'desde el ' + item.usrEsperasFechainicio.replace('T00:00:00Z[-03:00]', '') + ' por @' + item.usrEsperasUsuario + ' - ' + item.usrEsperasObserva
        }
      }
      )
      this.esperaRsm = res
    },
    highlightPend (text) {
      if (!text) return
      return text.replace(/^(--.*)$/gm, '<span style="color: yellow">$1</span>')
    },
    wrapTextWithStrong (htmlText) {
      const textsToWrap = ['ESPERAS ACTIVAS', 'PROXIMAS ACCIONES', 'ACCIONES REALIZADAS']
      var wrappedText = htmlText
      textsToWrap.forEach(text => {
        const regex = new RegExp(text, 'gi')
        wrappedText = wrappedText.replace(regex, `<strong>${text}</strong>`)
      })
      return wrappedText
    },
    highlightMatches (text) {
      if (!text) return
      const matchExists = text
        .toLowerCase()
        .includes(this.search.toLowerCase())
      if (!matchExists) return text
      const re = new RegExp(this.search, 'ig')
      return text.replace(re, matchedText => `<strong>${matchedText}</strong>`)
    },
    filterByProf (imp) {
      if (this.search.length === 0) {
        return true
      }
      return ((imp.usrImpHsiImpHsProf.toLowerCase().indexOf(this.search.toLowerCase()) > -1))
      // || (imp.usrImpHsiObs.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    },
    filterByProfDetHs (imp) {
      if (this.search.length === 0) {
        return true
      }
      return ((imp.profesional.toLowerCase().indexOf(this.search.toLowerCase()) > -1))
      // || (imp.usrImpHsiObs.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    },
    filteredByUsr (item) {
      if (this.searchEsp.length === 0) {
        return true
      }
      return ((item.usrEsperasUsuario.toLowerCase().indexOf(this.searchEsp.toLowerCase()) > -1))
    },
    getDetalleHs: function (e) {
      var self = this
      self.openFSL(self)
      const url = 'http://' + config.ip + '/ticket/detallehs/params;ticket=' + this.$route.params.id
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('detallehs', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.detallehs = JSON.parse(localStorage.getItem('detallehs'))
          self.updateDetalleHs()
          console.log(self.detallehs)
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    getTicket: function (e) {
      var self = this
      self.openFSL(self)
      const url = 'http://' + config.ip + '/ticket/Sttick/id/' + this.$route.params.id
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('ticket', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          localStorage.setItem('imputaciones', JSON.stringify(response.data.imputaciones))
          localStorage.setItem('acciones', JSON.stringify(response.data.acciones))
          localStorage.setItem('fases', JSON.stringify(response.data.fases))
          localStorage.setItem('esperas', JSON.stringify(response.data.esperas))
          self.getFromCache()
          self.resEsperas()
          self.updateChart()
          self.isLoaded = true
          document.title = response.data.usrSttickNroticket + ' - ' + response.data.usrSttickDescrip
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    }
  }
}
</script>
<style>
  .el-input__inner {
    color: red important;
  }
  span {
    white-space: pre-line;
  }
</style>
