import { createRouter, createWebHistory } from '@ionic/vue-router'
import larga from '../views/larga.vue'
import variablesLarga from '../views/variablesLarga.vue'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import TicketInfo from '../views/TktInfo.vue'
import Impu from '../views/Impu.vue'
import ImpuDiario from '../views/ImpuDiario.vue'
import ImpuSum from '../views/ImpuSum.vue'
import Top5 from '../views/Top5.vue'
import Tickets from '../views/Tickets.vue'
import AltaTkt from '../views/AltaTkt.vue'
import Android from '../views/Android.vue'
import Proyectos from '../views/Proyectos.vue'

// Vue.use(VueRouter)

const routes = [
  {
    path: '/larga',
    name: 'larga',
    component: larga
  },
  {
    path: '/variablesLarga',
    name: 'variablesLarga',
    component: variablesLarga
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  // {
  //   path: '/:param1',
  //   component: Login,
  //   props: true
  // },
  {
    path: '/TicketInfo/:id',
    name: 'TicketInfo',
    component: TicketInfo
  },
  {
    path: '/impu',
    name: 'Impu',
    component: Impu
  },
  {
    path: '/impusum',
    name: 'ImpuSum',
    component: ImpuSum
  },
  {
    path: '/top5',
    name: 'Top5',
    component: Top5
  },
  {
    path: '/Tickets',
    name: 'Tickets',
    component: Tickets
    // props: (route) => ({ parea: route.query.parea, pestado: route.query.pestado })
  },
  {
    path: '/ImpuDiario',
    name: 'ImpuDiario',
    component: ImpuDiario
  },
  {
    path: '/AltaTkt',
    name: 'AltaTkt',
    component: AltaTkt
  },
  {
    path: '/Android',
    name: 'Android',
    component: Android
  },
  {
    path: '/Proyectos',
    name: 'Proyectos',
    component: Proyectos
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
