<template>
  <body style="background-color:black">
  <el-drawer id= "objeto" v-if="isLoggedIn && textoVisible" v-model="textoVisible" title="notas" :with-header="false" @close='this.textoVisible = false'>
    <span>
      <div class="row">
        <div class="col-md-12">
          <el-input v-model="this.notas.usrNotas" placeholder='Texto' :rows="25" type="textarea" :readonly="impuOper==='C'"/>
        </div>
      </div>
      <br/>
      <div class="row" style="background-color:black">
        <div class="col-md-8">
          <!-- <el-button size="mini" type = "primary" @click="this.editednotas = { ...this.notas };this.saveChanges()" >Guardar</el-button> -->
        </div>
        <div class="col-md-4">
          <el-button type="danger" @click="this.textoVisible = false">
            <el-icon class="el-icon--left"><CircleCloseFilled /></el-icon>
            Salir
          </el-button>
          <!-- <el-button size="mini" type = "info" @click="this.textoVisible = false" >Salir</el-button> -->
        </div>
      </div>
    </span>
  </el-drawer>
  </body>
</template>
<style>
.el-drawer__body{
  background-color: black;
}
</style>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
import shared from '../shared/shared'
// import back from '../components/back.vue'
export default {
  extends: shared,
  name: 'Home',
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getToken'])
  },
  watch: {
    textoVisible (newVal) {
      if (newVal === false) {
        this.editednotas = { ...this.notas }
        this.saveChanges()
      } else {
        this.commonLogic()
      }
    }
  },
  components: {
    // back
  },
  data () {
    return {
      notas: {
        usrNoDebaja: null,
        usrNoFecalt: null,
        usrNoFecmod: null,
        usrNoOalias: null,
        usrNoUltopr: null,
        usrNoUserid: null,
        usrNotas: '',
        usrProfesional: ''
      },
      editednotas: {
      },
      textoVisible: false,
      asignados: [],
      profesional: ''
    }
  },
  async mounted () {
    this.commonLogic()
  },
  beforeMount () {
    document.title = 'Mis Notas'
  },
  methods: {
    async commonLogic () {
      var self = this
      self.openFSL(self)
      if (this.isLoggedIn) {
        await this.getProfesionals(self)
        self.asignados.forEach(function (element) {
          if (self.getToken.user === element.usrlogin) {
            self.notas.usrProfesional = element.value
          }
        })
        const url = 'http://' + config.ip + '/ticket/notas/' + self.notas.usrProfesional
        axios.get(url, {
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: self.getToken.token
          },
          mode: 'no-cors',
          credentials: 'include'
        })
          .then(function (response) {
            self.notas.usrNoDebaja = response.data.usrNoDebaja
            self.notas.usrNoFecalt = response.data.usrNoFecalt
            self.notas.usrNoFecmod = response.data.usrNoFecmod
            self.notas.usrNoOalias = response.data.usrNoOalias
            self.notas.usrNoUltopr = response.data.usrNoUltopr
            self.notas.usrNoUserid = response.data.usrNoUserid
            self.notas.usrNotas = response.data.usrNotas
            // self.notas.usrProfesional = response.data.usrProfesional
            self.closeFSL(self)
          })
          .catch(function (error) {
            console.log(error)
            self.closeFSL(self)
          })
      }
      self.closeFSL(self)
    },
    highlightPend (text) {
      if (!text) return
      return text.replace(/^(--.*)$/gm, '<span style="color: yellow">$1</span>')
    },
    saveChanges () {
      var pUrl = 'http://' + config.ip + '/ticket/notas'
      this.notas.usrNotas = this.editednotas.usrNotas
      var pBody = JSON.stringify(this.notas)
      this.persist(pUrl, 'PUT', pBody)
      this.textoVisible = false
    }
  }
}
</script>
