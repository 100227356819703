<template>
<div v-if="isLoggedIn" class="container-fluid">
  <back/>
  <form class = 'form-inline' role='form' @submit.prevent='getImpu'>
    <br/>
    <div class="row">
      <div class="col-md-3">
          <H2>Imputacion resumen:</H2>
      </div>
      <div class="col-md-5">
        <span style='padding-right: 10px;'>Rango de fechas</span>
        <el-date-picker
          v-model="value1"
          type="daterange"
          unlink-panels
          range-separator="|"
          start-placeholder="Desde"
          end-placeholder="Hasta"
          :shortcuts="shortcuts"
        >
        </el-date-picker>
      </div>
      <div class="col-md-2">
        <button type='submit' class='btn btn-primary mb-2'>
          Recuperar
        </button>
      </div>
      <div class="col-md-2">
      </div>
    </div>
  </form>
  <el-divider></el-divider>
  <!-- por consultor:
  <div>
    <apexchart
      type="bar"
      :options="chartOptions_p"
      :series="dataZonas_p"
      v-if="isLoaded"
      height="250"
    ></apexchart>
  </div> -->
  <div class='row'>
    <div class="col-sm-2">
      <el-select v-model="colaborador" filterable clearable placeholder="Colaborador Externo">
        <el-option
          v-for="item in colaboradores"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="col-sm-2">
      <el-select v-model="estado" filterable clearable placeholder="Estado">
        <el-option
          v-for="item in estados"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="col-sm-2">
      <el-select v-model="area" filterable clearable placeholder="Area">
        <el-option
          v-for="item in areas"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="col-sm-1">
      <el-select v-model="categoria" filterable clearable placeholder="Categoria">
        <el-option
          v-for="item in categorias"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div id='message' div class='col-md-2'>
      <el-input type='text' v-model='search' placeholder= 'Filtrar por...' />
    </div>
    <div class='col-md-1'>
      <!-- <div class='row'> -->
        <!-- <div class="form-group">
          <div class="form-check-inline"> -->
              <input type="checkbox" label="Ticket" v-model="ticketF"/> tkt
          <!-- </div> -->
          <!-- <div class="form-check-inline"> -->
              <input type="checkbox" label="Responsable" v-model="responsableF"/> consultor
          <!-- </div> -->
        <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
  <el-collapse v-model="activeHeaderName" accordion>
    <el-collapse-item title="por consultor" name="1">
      <div>
        <apexchart
          type="bar"
          :options="chartOpt_consult_p"
          :series="impConsultor_p"
          v-if="isLoaded"
          height="250"
        ></apexchart>
      </div>
      <strong> {{sum()}} hs </strong>
    </el-collapse-item>
    <el-collapse-item title="por empresa" name="2">
      por empresa:
      <div class='row'>
        <apexchart
          type="bar"
          :options="chartOpt_empresa_p"
          :series="impEmp_p"
          v-if="isLoaded"
          height="250"
        ></apexchart>
      </div>
      <strong> {{sum()}} hs </strong>
    </el-collapse-item>
    <el-collapse-item title="por area" name="3">
      por area:
      <div class='row'>
        <apexchart
          type="bar"
          :options="chartOpt_area_p"
          :series="impArea_p"
          v-if="isLoaded"
          height="250"
        ></apexchart>
      </div>
    </el-collapse-item>
    <el-collapse-item title="detalle c/ apertura ticket" name="4">
      <div class='row'>
        <br>
        <table id='table_id'
              class='table table-borderless table-condensed'
              >
          <thead>
            <tr class="blue">
                <th>#</th>
                <th>Profesional</th>
                <th>Ticket</th>
                <th>Proyecto</th>
                <th>Titulo</th>
                <th>Empresa</th>
                <th>Area</th>
                <th>Status</th>
                <th>Categoria</th>
                <th>Hs</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td><strong>Resumen</strong></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><strong>{{sum()}}</strong></td>
            </tr>
            <tr v-for='(imp, i) in filteredImpu' :key='i'>
              <td>{{ i + 1 }}</td>
              <td v-html="imp.profesional"></td>
              <td><router-link v-bind:to="'/?ticket='+imp.ticket">{{imp.ticket}}</router-link></td>
              <td v-html="imp.proyecto === 0 ? '' : imp.proyecto"></td>
              <td v-html="imp.titulo"></td>
              <td v-html="imp.empresa"></td>
              <td v-html="imp.area"></td>
              <td>
                <el-tag v-if="imp.estado=='S'">Asignado</el-tag>
                <el-tag v-if="imp.estado=='F'">Cerrado</el-tag>
                <el-tag type="success" v-if="imp.estado=='C'">En curso</el-tag>
                <el-tag type="info" v-if="imp.estado=='P'">Sin procesar</el-tag>
                <el-tag type="warning" v-if="imp.estado=='D'">Demorado</el-tag>
                <el-tag type="danger" v-if="imp.estado=='U'">Bloqueado</el-tag>
              </td>
              <td v-html="imp.categoria"></td>
              <td v-html="this.numberFormat(imp.horas,2)"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-collapse-item>
    <el-collapse-item title="detalle c/ apertura empresa" name="5">
      <div class='row'>
        <br>
        <table id='table_id'
              class='table table-bordered table-condensed vue-table'
              >
          <thead>
            <tr class="blue">
                <th>Profesional</th>
                <th>Ticket</th>
                <th>Proyecto</th>
                <th>Titulo</th>
                <th>Empresa</th>
                <th>Area</th>
                <th>Status</th>
                <th>Hs</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td><strong>Resumen</strong></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><strong>{{sumv2()}}</strong></td>
            </tr>
            <tr v-for='(imp, i) in filteredImpuv2' :key='i'>
              <td v-html="imp.profesional"></td>
              <td><router-link v-bind:to="'/TicketInfo/'+imp.ticket">{{imp.ticket}}</router-link></td>
              <td v-html="imp.proyecto === 0 ? '' : imp.proyecto"></td>
              <td v-html="imp.titulo"></td>
              <td v-html="imp.empresa"></td>
              <td v-html="imp.area"></td>
              <td>
                <el-tag v-if="imp.estado=='S'">Asignado</el-tag>
                <el-tag v-if="imp.estado=='F'">Cerrado</el-tag>
                <el-tag type="success" v-if="imp.estado=='C'">En curso</el-tag>
                <el-tag type="info" v-if="imp.estado=='P'">Sin procesar</el-tag>
                <el-tag type="warning" v-if="imp.estado=='D'">Demorado</el-tag>
                <el-tag type="danger" v-if="imp.estado=='U'">Bloqueado</el-tag>
              </td>
              <td v-html="this.numberFormat(imp.horas,2)"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-collapse-item>
  </el-collapse>
  <el-divider></el-divider>
  <!-- <div class='row'>
    <div class="col-md-3">
      <el-select v-model="colaborador" filterable clearable placeholder="Colaborador Externo">
        <el-option
          v-for="item in colaboradores"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
  </div> -->
</div>
<el-dialog
  title="Detalles"
  v-model="dialogVisible"
  width="50%"
>
  <span>
    <p p align="justify" style='white-space: pre-line'>
    {{dialogo}}
    </p>
  </span>
</el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
import router from '../router'
import shared from '../shared/shared'
import back from '../components/back.vue'
export default {
  extends: shared,
  components: {
    back
  },
  mounted: function () {
    document.title = 'Summary Report'
    var self = this
    if (localStorage.getItem('imputacionSum')) {
      try {
        self.impu = JSON.parse(localStorage.getItem('imputacionSum'))
      } catch (e) {
        localStorage.removeItem('imputacionSum')
      }
    }
    if (localStorage.getItem('imputacionSumV2')) {
      try {
        self.impAreaEmp = JSON.parse(localStorage.getItem('imputacionSumV2'))
      } catch (e) {
        localStorage.removeItem('imputacionSumV2')
      }
    }
    if (localStorage.getItem('area')) {
      self.area = localStorage.getItem('area')
    }
    if (localStorage.getItem('categoria')) {
      self.categoria = localStorage.getItem('categoria')
    }
    if (localStorage.getItem('ImpuSum_estado')) {
      self.estado = localStorage.getItem('ImpuSum_estado')
    }
    if (localStorage.getItem('responsableF')) {
      self.responsableF = localStorage.getItem('responsableF')
    }
    if (localStorage.getItem('ticketF')) {
      self.ticketF = localStorage.getItem('ticketF')
    }
    if (localStorage.getItem('searchImp')) {
      self.search = localStorage.getItem('searchImp')
    }
    if (localStorage.getItem('from')) {
      self.value1 = [localStorage.getItem('from'), localStorage.getItem('to')]
    }
    if (localStorage.getItem('colaboradorF')) {
      self.colaborador = localStorage.getItem('colaboradorF')
    }
    self.updateChart()
    self.isLoaded = true
  },
  watch: {
    area (newVal) {
      localStorage.setItem('area', newVal)
      this.updateChart()
    },
    categoria (newVal) {
      localStorage.setItem('categoria', newVal)
      this.updateChart()
    },
    estado (newVal) {
      localStorage.setItem('ImpuSum_estado', newVal)
      this.updateChart()
    },
    colaborador (newVal) {
      localStorage.setItem('colaboradorF', newVal)
      this.updateChart()
    },
    ticketF (newVal) {
      localStorage.setItem('ticketF', newVal)
    },
    responsableF (newVal) {
      localStorage.setItem('responsableF', newVal)
    },
    value1 (newVal) {
      localStorage.setItem('from', newVal[0])
      localStorage.setItem('to', newVal[1])
    },
    search (newVal) {
      localStorage.setItem('searchImp', newVal)
      this.updateChart()
    }
  },
  data: function () {
    return {
      activeHeaderName: 1,
      isLoaded: false,
      impAreaEmp: [],
      categoria: '',
      categorias: [
        {
          value: 'A',
          label: 'Asistencia'
        }, {
          value: 'R',
          label: 'Requisito'
        },
        {
          value: 'F',
          label: 'Falla'
        }],
      area: '',
      areas: [
        {
          value: 'ADM',
          label: 'Administracion Central'
        },
        {
          value: 'AGRB',
          label: 'Agro brandsen'
        },
        {
          value: 'AGRT',
          label: 'Agro tandil'
        },
        {
          value: 'BC',
          label: 'BLUE-C'
        },
        {
          value: 'CE',
          label: 'ACTIVA - Consultores Externos'
        },
        {
          value: 'COM',
          label: 'Compras'
        },
        {
          value: 'CONT',
          label: 'Contabilidad e Impuestos'
        },
        {
          value: 'DIR',
          label: 'Direccion'
        },
        {
          value: 'EESS',
          label: 'Estacion Varela'
        },
        {
          value: 'GERA',
          label: 'Gerencia Administracion'
        },
        {
          value: 'GERAGR',
          label: 'Gerencia Agro'
        },
        {
          value: 'GERM',
          label: 'Gerencia Mantenimiento'
        },
        {
          value: 'GERT',
          label: 'Gerencia Transporte'
        },
        {
          value: 'IT',
          label: 'Tecnologia Informatica'
        },
        {
          value: 'LOGI',
          label: 'Logistica'
        },
        {
          value: 'RRHH',
          label: 'Recursos Humanos'
        },
        {
          value: 'TALL',
          label: 'Taller'
        },
        {
          value: 'TESO',
          label: 'Tesoreria'
        }
      ],
      estados: [{
        value: 'NA',
        label: 'No Aplica'
      }, {
        value: 'A',
        label: 'Abiertos'
      }, {
        value: 'P',
        label: 'Sin Procesar'
      }, {
        value: 'S',
        label: 'Asignado'
      }, {
        value: 'D',
        label: 'Demorado'
      }, {
        value: 'U',
        label: 'Bloqueado'
      }, {
        value: 'C',
        label: 'En curso'
      }, {
        value: 'F',
        label: 'Finalizado'
      }],
      estado: '',
      colaboradores: [{
        value: 'NA',
        label: 'No Aplica'
      }, {
        value: 'S',
        label: 'Si'
      }, {
        value: 'N',
        label: 'No'
      }],
      chartOptions_p: [],
      chartOpt_consult_p: [],
      dataZonas_p: [],
      impEmp_p: [],
      impArea_p: [],
      impConsultor_p: [],
      colaborador: '',
      dialogVisible: false,
      date1: null,
      date2: null,
      value1: '',
      search: '',
      impu: [],
      areaF: true,
      ticketF: true,
      responsableF: true,
      descripcionF: true,
      shortcuts: [{
        text: '1 Week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        }
      }, {
        text: '2 Weeks',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 14)
          return [start, end]
        }
      }, {
        text: '1 Month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        }
      }, {
        text: '3 Months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        }
      }]
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn']),
    filteredImpu () {
      return this.impu
        .filter(this.filterByCategoria)
        .filter(this.filterByArea)
        .filter(this.filterByExterno)
        .filter(this.filterByProf)
        .filter(this.filterByStatus)
        .sort((a, b) => a.horas > b.horas ? -1 : 1)
    },
    filteredImpuv2 () {
      return this.impAreaEmp
        .filter(this.filterByCategoria)
        .filter(this.filterByArea)
        .filter(this.filterByExterno)
        .filter(this.filterByProf)
        .filter(this.filterByStatus)
        .sort((a, b) => a.horas > b.horas ? -1 : 1)
    }
  },
  methods: {
    updateChart () {
      var xZonaP = []
      var yZonaP = []
      this.impu.forEach(function (x) {
        var exists = false
        var item = 0
        xZonaP.forEach(function (y) {
          console.log('x profesional:' + x.profesional)
          console.log('y profesional:' + y)
          if (x.profesional === y) {
            exists = true
            yZonaP[item] = Math.round((yZonaP[item] + x.horas) * 100) / 100
          }
          item++
        })
        if (!exists) {
          xZonaP.push(x.profesional)
          yZonaP.push(Math.round((x.horas) * 100) / 100)
        }
      })
      this.dataZonas_p = [
        {
          data: yZonaP,
          type: 'column',
          name: 'horas'
        }
      ]
      this.chartOptions_p = {
        colors: ['#00adff'],
        xaxis: {
          categories: xZonaP,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5
          },
          labels: {
            offsetY: -5,
            rotate: -45,
            style: {
              colors: '#bbbfc0',
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 420
            }
          }
        },
        dataLabels: {
          offsetY: 15,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.9
          },
          style: {
            colors: ['white', 'white'],
            fontSize: '15px',
            fontWeight: 640
          }
        },
        legend: {
          show: true,
          labels: {
            useSeriesColors: true
          }
        }
      }
      var ximpEmp = []
      var yimpEmp = []
      // var self = this
      // self.colaborador = self.colaborador || 'NA'
      this.filteredImpuv2.forEach(function (x) {
        // if ((self.colaborador === x.externo || self.colaborador === 'NA')) {
        var exists = false
        var item = 0
        ximpEmp.forEach(function (y) {
          if (x.empresa === y) {
            exists = true
            yimpEmp[item] = Math.round((yimpEmp[item] + Number(x.horas)) * 100) / 100
          }
          item++
        })
        if (!exists) {
          ximpEmp.push(x.empresa)
          yimpEmp.push(Math.round((Number(x.horas)) * 100) / 100)
        }
        // }
      })
      this.impEmp_p = [
        {
          data: yimpEmp,
          type: 'column',
          name: 'horas'
        }
      ]
      this.chartOpt_empresa_p = {
        colors: ['#00adff'],
        xaxis: {
          categories: ximpEmp,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5
          },
          labels: {
            offsetY: -5,
            rotate: -45,
            style: {
              colors: '#bbbfc0',
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 420
            }
          }
        },
        dataLabels: {
          offsetY: 15,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.9
          },
          style: {
            colors: ['white', 'white'],
            fontSize: '15px',
            fontWeight: 640
          }
        },
        legend: {
          show: true,
          labels: {
            useSeriesColors: true
          }
        }
      }
      var ximpArea = []
      var yimpArea = []
      this.filteredImpuv2.forEach(function (x) {
        // console.log('colaborador:' + x.area)
        var exists = false
        var item = 0
        // if ((self.colaborador === x.externo || self.colaborador === 'NA')) {
        ximpArea.forEach(function (y) {
          if (x.area === y) {
            exists = true
            yimpArea[item] = Math.round((yimpArea[item] + Number(x.horas)) * 100) / 100
          }
          item++
        })
        if (!exists) {
          ximpArea.push(x.area)
          yimpArea.push(Math.round((Number(x.horas)) * 100) / 100)
        }
        // }
      })
      console.log('colaborador:' + ximpArea)
      this.impArea_p = [
        {
          data: yimpArea,
          type: 'column',
          name: 'horas'
        }
      ]
      this.chartOpt_area_p = {
        colors: ['#00adff'],
        xaxis: {
          categories: ximpArea,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5
          },
          labels: {
            offsetY: -5,
            rotate: -45,
            style: {
              colors: '#bbbfc0',
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 420
            }
          }
        },
        dataLabels: {
          offsetY: 15,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.9
          },
          style: {
            colors: ['white', 'white'],
            fontSize: '15px',
            fontWeight: 640
          }
        },
        legend: {
          show: true,
          labels: {
            useSeriesColors: true
          }
        }
      }
      var ximpCol = []
      var yimpCol = []
      // var self = this
      // self.colaborador = self.colaborador || 'NA'
      this.filteredImpuv2.forEach(function (x) {
        // if ((self.colaborador === x.externo || self.colaborador === 'NA')) {
        var exists = false
        var item = 0
        ximpCol.forEach(function (y) {
          if (x.profesional === y) {
            exists = true
            yimpCol[item] = Math.round((yimpCol[item] + Number(x.horas)) * 100) / 100
          }
          item++
        })
        if (!exists) {
          ximpCol.push(x.profesional)
          yimpCol.push(Math.round((Number(x.horas)) * 100) / 100)
        }
        // }
      })
      this.impConsultor_p = [
        {
          data: yimpCol,
          type: 'column',
          name: 'horas'
        }
      ]
      this.chartOpt_consult_p = {
        colors: ['#00adff'],
        xaxis: {
          categories: ximpCol,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5
          },
          labels: {
            offsetY: -5,
            rotate: -45,
            style: {
              colors: '#bbbfc0',
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 420
            }
          }
        },
        dataLabels: {
          offsetY: 15,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.9
          },
          style: {
            colors: ['white', 'white'],
            fontSize: '15px',
            fontWeight: 640
          }
        },
        legend: {
          show: true,
          labels: {
            useSeriesColors: true
          }
        }
      }
    },
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL () {
      this.loading.close()
    },
    sum () {
      var res = 0
      this.filteredImpu.forEach(function (item) {
        res = res + item.horas
      })
      return Math.round(res * 100) / 100
    },
    sumv2 () {
      var res = 0
      this.filteredImpuv2.forEach(function (item) {
        res = res + item.horas
      })
      return Math.round(res * 100) / 100
    },
    highlightMatches (text) {
      if (!text) return
      const matchExists = text
        .toLowerCase()
        .includes(this.search.toLowerCase())
      if (!matchExists) return text
      const re = new RegExp(this.search, 'ig')
      return text.replace(re, matchedText => `<strong>${matchedText}</strong>`)
    },
    filterByCategoria (imp) {
      var filter1 = this.categoria || 'NA'
      if ((this.categoria === imp.categoria || filter1 === 'NA')) {
        return true
      }
      return false
    },
    filterByArea (imp) {
      var filter1 = this.area || 'NA'
      if ((this.area === imp.area || filter1 === 'NA')) {
        return true
      }
      return false
    },
    filterByExterno (imp) {
      console.log('colaborador:' + imp.externo)
      var colaborador1 = this.colaborador || 'NA'
      if ((this.colaborador === imp.externo || colaborador1 === 'NA')) {
        console.log('colaborador: true')
        return true
      }
      console.log('colaborador: false')
      return false
    },
    filterByProf (imp) {
      if (this.search.length === 0) {
        return true
      }
      if (this.responsableF.toString().toLowerCase() === 'false' & this.ticketF.toString().toLowerCase() === 'false') {
        return true
      }
      return ((imp.ticket.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.ticketF) || (imp.profesional.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.responsableF))
    },
    filterByStatus (imp) {
      return imp.estado.toString().toLowerCase().indexOf(this.estado.toLowerCase()) > -1
    },
    getImpu: function (e) {
      var self = this
      self.openFSL()
      const url = 'http://' + config.ip + '/ticket/imputacion/summarized.v1/params/params;from=' + self.strToDate(self.value1[0]) + ';to=' + self.strToDate(self.value1[1])
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('imputacionSum', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.impu = JSON.parse(localStorage.getItem('imputacionSum'))
          self.getImpu_V2()
          self.updateChart()
          self.closeFSL()
          self.isLoaded = true
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    getImpu_V2: function (e) {
      var self = this
      self.openFSL()
      const url = 'http://' + config.ip + '/ticket/imputacion/summarized.v2/params/params;from=' + self.strToDate(self.value1[0]) + ';to=' + self.strToDate(self.value1[1])
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('imputacionSumV2', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.impAreaEmp = JSON.parse(localStorage.getItem('imputacionSumV2'))
          self.updateChart()
          self.closeFSL()
          self.isLoaded = true
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    strToDate (str) {
      var date = new Date(str)
      var mnth = ('0' + (date.getMonth() + 1)).slice(-2)
      var day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear(), mnth, day].join('')
    }
  }
}
</script>
