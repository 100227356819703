<template>
pagina de prueba
</template>
<style scoped>
img {
  display: relative;
  max-width: 90%;
  height: auto;
}
</style>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
import shared from '../shared/shared'
// import back from '../components/back.vue'
export default {
  extends: shared,
  name: 'Home',
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getToken'])
  },
  components: {
    // back
  },
  data () {
    return {
      notas: {
        usrNoDebaja: null,
        usrNoFecalt: null,
        usrNoFecmod: null,
        usrNoOalias: null,
        usrNoUltopr: null,
        usrNoUserid: null,
        usrNotas: '',
        usrProfesional: ''
      },
      editednotas: {
      },
      textoVisible: false,
      asignados: [],
      profesional: ''
    }
  },
  async mounted () {
    var self = this
    self.openFSL(self)
    self.closeFSL(self)
    if (this.isLoggedIn) {
      await this.getProfesionals(self)
      self.asignados.forEach(function (element) {
        if (self.getToken.user === element.usrlogin) {
          self.notas.usrProfesional = element.value
        }
      })
      const url = 'http://' + config.ip + '/ticket/notas/' + self.notas.usrProfesional
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          self.notas.usrNoDebaja = response.data.usrNoDebaja
          self.notas.usrNoFecalt = response.data.usrNoFecalt
          self.notas.usrNoFecmod = response.data.usrNoFecmod
          self.notas.usrNoOalias = response.data.usrNoOalias
          self.notas.usrNoUltopr = response.data.usrNoUltopr
          self.notas.usrNoUserid = response.data.usrNoUserid
          self.notas.usrNotas = response.data.usrNotas
          // self.notas.usrProfesional = response.data.usrProfesional
          self.closeFSL(self)
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL(self)
        })
    }
  },
  beforeMount () {
    document.title = 'Mis Notas'
  },
  methods: {
    highlightPend (text) {
      if (!text) return
      return text.replace(/^(--.*)$/gm, '<span style="color: yellow">$1</span>')
    },
    saveChanges () {
      var pUrl = 'http://' + config.ip + '/ticket/notas'
      this.notas.usrNotas = this.editednotas.usrNotas
      var pBody = JSON.stringify(this.notas)
      this.persist(pUrl, 'PUT', pBody)
      this.textoVisible = false
    }
  }
}
</script>
