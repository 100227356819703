<template>
  <back/>
  <AltaTktCmp/>
</template>
<script>
import { mapGetters } from 'vuex'
import router from '../router'
import back from '../components/back.vue'
export default {
  name: 'AltaTkt',
  components: {
    back
  },
  mounted: async function () {
    if (!this.isLoggedIn) {
      router.push('/')
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['getArea']),
    ...mapGetters(['isLoggedIn'])
  }
}
</script>
