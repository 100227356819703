<template>
<div v-if="isLoggedIn" class="container-fluid">
  <back/>
  <form class = 'form-inline' role='form' @submit.prevent='getImpu'>
    <br/>
    <div class="row">
      <div class="col-md-3">
        <H2>Imputacion Diaria:</H2>
      </div>
      <div class="col-md-5">
        <span style='padding-right: 10px;'>Rango de fechas</span>
        <el-date-picker
          v-model="value1"
          type="daterange"
          unlink-panels
          range-separator="|"
          start-placeholder="Desde"
          end-placeholder="Hasta"
          :shortcuts="shortcuts"
        >
        </el-date-picker>
      </div>
      <div class="col-md-2">
        <button type='submit' class='btn btn-primary mb-2'>
          Recuperar
        </button>
      </div>
      <div class="col-md-2">
      </div>
    </div>
  </form>
  <el-divider>Filtros rapidos</el-divider>
  <div class="row">
    <div class="col-md-2">
      <!-- Profesional -->
      <el-select
        v-model="asignado"
        filterable
        clearable
        placeholder="Asignado"
      >
        <el-option
          v-for="item in asignados"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="col-md-2">
      <!-- Ticket -->
      <el-input type='text' v-model='ticketP' placeholder= 'Ticket' />
    </div>
    <div class="col-md-8">
      <el-input type='text' v-model='search' placeholder= 'Filtrar por...' />
    </div>
  </div>
  <div>
    <apexchart
        type="bar"
        :options="chartOptions_p"
        :series="dataZonas_p"
        v-if="isLoaded"
        height="250"
      ></apexchart>
  </div>
  <div class='row'>
    <div id='message' div class='col-md-12'>
      <br>
      <table id='table_id'
            class='table table-borderless table-condensed vue-table'
            >
        <thead>
          <tr class="blue">
              <th>Profesional</th>
              <th>Fecha</th>
              <th>Ticket</th>
              <th>Titulo</th>
              <th>Status</th>
              <th>Observacion</th>
              <th>Hs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
          <td><strong>Resumen</strong></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td><strong>{{sum()}}</strong></td>
          </tr>
          <tr v-for='(imp, i) in filteredTabla' :key='i'>
            <td v-html="imp.profesional"></td>
            <td v-html="imp.fecha"></td>
            <!-- <td><router-link v-bind:to="'/TicketInfo/'+imp.ticket">{{ imp.ticket }}</router-link></td> -->
            <td><router-link v-bind:to="'/?ticket='+imp.ticket">{{imp.ticket}}</router-link></td>
            <td v-html="imp.titulo"></td>
            <td>
              <el-tag v-if="imp.estado=='S'">Asignado</el-tag>
              <el-tag v-if="imp.estado=='F'">Cerrado</el-tag>
              <el-tag type="success" v-if="imp.estado=='C'">En curso</el-tag>
              <el-tag type="info" v-if="imp.estado=='P'">Sin procesar</el-tag>
              <el-tag type="warning" v-if="imp.estado=='D'">Demorado</el-tag>
              <el-tag type="danger" v-if="imp.estado=='U'">Bloqueado</el-tag>
            </td>
            <td>{{imp.texto.substring(0,100)}}
              <el-button type="text" @click="mostrarImputacionV2(this, filteredTabla, i)">Ampliar Detalle</el-button>
            </td>
            <td v-html="imp.horas"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<el-dialog
  title="Detalles"
  v-model="dialogVisible"
  width="50%"
>
  <span>
    <div class="row">
    <div class="col-md-5"></div>
    <div class="col-md-2">
      <el-button-group>
        <el-button size="mini" type = "primary" icon="el-icon-arrow-left" @click="mostrarImputacionV2(this, filteredTabla, impIndex - 1)" plain></el-button>
        <el-button size="mini" type = "primary" icon="el-icon-arrow-right" @click="mostrarImputacionV2(this, filteredTabla, impIndex + 1)" plain></el-button>
      </el-button-group>
    </div>
    <div class="col-md-5"></div>
    </div>
    <p p align="justify" style='white-space: pre-line'>
    {{dialogo}}
    </p>
    <div v-if="true">{{impIndex + 1}} / {{this.filteredTabla.length}}</div>
  </span>
</el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
import router from '../router'
import shared from '../shared/shared'
import back from '../components/back.vue'
export default {
  extends: shared,
  components: {
    back
  },
  beforeMount () {
    document.title = 'Daily Report'
    if (this.isLoggedIn) {
      if (localStorage.getItem('asignados')) {
        this.asignados = JSON.parse(localStorage.getItem('asignados'))
      } else {
        this.getProfesionals(this)
      }
    }
  },
  mounted: function () {
    var self = this
    if (localStorage.getItem('imputacion')) {
      try {
        self.impu = JSON.parse(localStorage.getItem('imputacion'))
      } catch (e) {
        localStorage.removeItem('imputacion')
      }
    }
    if (localStorage.getItem('responsableF')) {
      self.responsableF = localStorage.getItem('responsableF')
    }
    if (localStorage.getItem('asignado')) {
      self.asignado = localStorage.getItem('asignado')
    }
    if (localStorage.getItem('ticketP')) {
      self.ticketP = localStorage.getItem('ticketP')
    }
    if (localStorage.getItem('ticketF')) {
      self.ticketF = localStorage.getItem('ticketF')
    }
    if (localStorage.getItem('searchImp')) {
      self.search = localStorage.getItem('searchImp')
    }
    if (localStorage.getItem('from')) {
      self.value1 = [localStorage.getItem('from'), localStorage.getItem('to')]
    }
    if (localStorage.getItem('colaboradorF')) {
      self.colaborador = localStorage.getItem('colaboradorF')
    }
    self.updateChart()
    self.isLoaded = true
  },
  watch: {
    asignado (newVal) {
      localStorage.setItem('asignado', newVal)
      this.updateChart()
    },
    colaborador (newVal) {
      localStorage.setItem('colaboradorF', newVal)
    },
    ticketP (newVal) {
      localStorage.setItem('ticketP', newVal)
      this.updateChart()
    },
    ticketF (newVal) {
      localStorage.setItem('ticketF', newVal)
    },
    responsableF (newVal) {
      localStorage.setItem('responsableF', newVal)
    },
    value1 (newVal) {
      localStorage.setItem('from', newVal[0])
      localStorage.setItem('to', newVal[1])
    },
    search (newVal) {
      localStorage.setItem('searchImp', newVal)
    }
  },
  data: function () {
    return {
      datos: [],
      isLoaded: false,
      chartOptions_p: [],
      dataZonas_p: [],
      asignado: '',
      asignados: [],
      colaboradores: [{
        value: 'S',
        label: 'Si'
      }, {
        value: 'N',
        label: 'No'
      }],
      colaborador: '',
      dialogVisible: false,
      date1: null,
      date2: null,
      value1: '',
      ticketP: '',
      search: '',
      impu: [],
      areaF: true,
      ticketF: true,
      responsableF: true,
      descripcionF: true,
      shortcuts: [{
        text: '1 Week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        }
      }, {
        text: '2 Weeks',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 14)
          return [start, end]
        }
      }, {
        text: '1 Month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        }
      }, {
        text: '3 Months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        }
      }]
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn']),
    filteredBarras () {
      return this.impu
        .filter(this.filterBarras)
        .sort((a, b) => a.fecha < b.fecha ? -1 : 1)
    },
    filteredTabla () {
      return this.datos
        .filter(this.filterTabla)
        .sort((a, b) => a.fecha < b.fecha ? -1 : 1)
    }
  },
  methods: {
    updateChart () {
      var xZonaP = []
      var yZonaP = []
      this.datos = this.filteredBarras
      this.datos.forEach(function (x) {
        var exists = false
        var item = 0
        xZonaP.forEach(function (y) {
          if (x.fecha.split('-')[2] + '-' + x.fecha.split('-')[1] === y) {
            exists = true
            yZonaP[item] = Math.round((yZonaP[item] + x.horas) * 100) / 100
          }
          item++
        })
        if (!exists) {
          xZonaP.push(x.fecha.split('-')[2] + '-' + x.fecha.split('-')[1])
          yZonaP.push(Math.round((x.horas) * 100) / 100)
        }
      })
      this.dataZonas_p = [
        {
          data: yZonaP,
          type: 'bar',
          name: 'horas'
        }
      ]
      this.chartOptions_p = {
        colors: ['#00adff'],
        xaxis: {
          categories: xZonaP,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.5
          },
          labels: {
            offsetY: -5,
            rotate: -45,
            style: {
              colors: '#bbbfc0',
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 420
            }
          }
        },
        dataLabels: {
          offsetY: 15,
          dropShadow: {
            enabled: true,
            left: 2,
            top: 2,
            opacity: 0.9
          },
          style: {
            colors: ['white', 'white'],
            fontSize: '15px',
            fontWeight: 640
          }
        },
        legend: {
          show: true,
          labels: {
            useSeriesColors: true
          }
        }
      }
    },
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL () {
      this.loading.close()
    },
    sum () {
      var res = 0
      this.filteredTabla.forEach(function (item) {
        res = res + item.horas
      })
      return Math.round(res * 100) / 100
    },
    highlightMatches (text) {
      if (!text) return
      const matchExists = text
        .toLowerCase()
        .includes(this.search.toLowerCase())
      if (!matchExists) return text
      const re = new RegExp(this.search, 'ig')
      return text.replace(re, matchedText => `<strong>${matchedText}</strong>`)
    },
    filterByProf (imp) {
      if (this.search.length === 0) {
        return true
      }
      if (this.responsableF.toString().toLowerCase() === 'false' & this.ticketF.toString().toLowerCase() === 'false') {
        return true
      }
      return ((imp.ticket.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.ticketF) ||
              (imp.profesional.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.responsableF))
    },
    filterBarras (item) {
      if (this.asignado.length !== 0 && this.ticketP.length !== 0) {
        return ((item.ticket.toString().toLowerCase().indexOf(this.ticketP) > -1) &&
              (item.profesional.toString().toLowerCase().indexOf(this.asignado.toLowerCase()) > -1))
      } else if (this.asignado.length !== 0) {
        return (item.profesional.toString().toLowerCase().indexOf(this.asignado.toLowerCase()) > -1)
      } else if (this.ticketP.length !== 0) {
        console.log('#' + this.ticketP)
        return (item.ticket.toString().toLowerCase().indexOf(this.ticketP.toLowerCase()) > -1)
      } else return true
    },
    filterTabla (item) {
      if (this.search.length === 0) {
        return true
      }
      // if (this.responsableF.toString().toLowerCase() === 'false' & this.ticketF.toString().toLowerCase() === 'false') {
      //   return true
      // }
      return ((item.ticket.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1) || (item.profesional.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1))
    },
    getImpu: function (e) {
      var self = this
      self.openFSL()
      const url = 'http://' + config.ip + '/ticket/imputacion/params/params;from=' + self.strToDate(self.value1[0]) + ';to=' + self.strToDate(self.value1[1]) + ';ce=' + self.colaborador
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('imputacion', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.impu = JSON.parse(localStorage.getItem('imputacion'))
          self.updateChart()
          self.closeFSL()
          self.isLoaded = true
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    strToDate (str) {
      var date = new Date(str)
      var mnth = ('0' + (date.getMonth() + 1)).slice(-2)
      var day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear(), mnth, day].join('')
    }
  }
}
</script>
