<template>
  <div class="row">
    <div class="col-md-3">
      <el-input v-model="ticket.usrSttickDescrip" placeholder="Titulo"/>
    </div>
    <div class="col-md-2">
      <el-date-picker v-model="ticket.usrSttickFechaAlta" type="date" placeholder="Fecha Alta">
      </el-date-picker>
    </div>
  </div>
  <br/>
  <div class="row">
    <div class="col-md-3">
      <el-select v-model="ticket.usrSttickEmpresa" filterable clearable placeholder="Empresa">
        <el-option
          v-for="item in empresas"
          :key="item.codigo"
          :label="item.codigo"
          :value="item.codigo"
        >
        </el-option>
      </el-select>
    </div>
    <div class="col-md-3">
      <el-select v-model="ticket.usrSttickPropie" filterable clearable placeholder="Solicitante">
        <el-option
          v-for="item in usuarios"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
  <br/>
  <div class="row">
    <div class="col-md-3">
      <el-select v-model="ticket.usrSttickUsuario" filterable clearable placeholder="Usuario">
        <el-option
          v-for="item in usuarios"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="col-md-3">
      <el-select @change="categoriaValid" v-model="ticket.usrSttickCategoria" filterable clearable :disabled="esperaOper==='C'" placeholder="Categoria">
        <el-option
          v-for="item in categorias"
          :key="item.usrCatCat"
          :label="item.usrCatDescrp"
          :value="item.usrCatCat"
        >
        </el-option>
      </el-select>
    </div>
  </div>
  <br/>
  <div class="row">
    <div class="col-md-3">
      <el-select v-model="ticket.usrSttickSubcat" filterable clearable placeholder="Sub cat" :disabled="esperaOper==='C'">
      <el-option
        v-for="item in subCategorias"
        :key="item.usrSubPK.usrSubSubcat"
        :label="item.usrSubPK.usrSubSubcat"
        :value="item.usrSubPK.usrSubSubcat"
      >
      </el-option>
      </el-select>
    </div>
    <div class="col-md-3">
      <el-select v-model="ticket.usrSttickRecurrente" filterable clearable placeholder="Recurrente">
        <el-option
          v-for="item in optRecurrentes"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
  <br/>
  <div class="row">
    <div class="col-md-2">
      <el-input v-model="ticket.usrSttickProyecto" readonly placeholder="Proyecto"/>
    </div>
    <!-- <div class="col-md-2">
      <el-input v-on:keypress="this.isNumber(event)" v-model="ticket.usrSttickOrdusr" placeholder="Prioridad"/>
    </div> -->
  </div>
  <br/>
  <div class="dialog-footer align-right">
    <el-button type="primary" @click="saveChanges">Guardar</el-button>
    <el-button type="info" @click="showDialog = false;router.go(-1)">Cancelar</el-button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import router from '../router'
import shared from '../shared/shared'
export default {
  extends: shared,
  name: 'imp-list-row',
  beforeMount () {
    document.title = 'Nueva tarea'
  },
  mounted: async function () {
    if (!this.isLoggedIn) {
      router.push('/')
    } else {
      this.ticket.usrSttickFechaAlta = new Date(new Date().toDateString())
      this.ticket.usrSttickRecurrente = 'N'
      await this.getEmpresas(this)
      await this.getCategorias(this)
      await this.getSubCategorias(this, this.ticket.usrSttickCategoria)
      await this.getProfesionals(this)
      await this.getUsers(this)
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      this.categorias = JSON.parse(localStorage.getItem('categorias'))
      this.subCategorias = JSON.parse(localStorage.getItem('subCategorias'))
      // this.profesionales = JSON.parse(localStorage.getItem('asignados'))
      this.usuarios = JSON.parse(localStorage.getItem('usuarios'))
    }
  },
  data: function () {
    return {
      asignados: [],
      usuarios: [],
      ticket: {
        usrSttickOrden1: null,
        usrSttickOrden2: null,
        usrSttickProyecto: null,
        usrSttickOrdusr: null,
        usrSttickFchord: '1900-01-01T00:00:00Z[-03:00]',
        usrSttickFechaentrega: '1900-01-01T00:00:00Z[-03:00]',
        usrSttickFechaFin: '1900-01-01T00:00:00Z[-03:00]',
        usrSttickCategoria: '',
        usrSttickSubcat: '',
        usrSttickDescrip: '',
        usrSttickFechaAlta: '1900-01-01T00:00:00Z[-03:00]',
        usrSttickPropie: '',
        usrSttickUsuario: '',
        usrSttickEmpresa: '',
        usrSttickDepto: 'IT',
        usrSttickNroticket: 0,
        usrSttickRecurrente: '',
        fases: [],
        resueltos: [],
        esperas: [],
        tags: [],
        minutas: [],
        imputaciones: []
      },
      empresas: [],
      categorias: [],
      subCategorias: [],
      optRecurrentes: [{
        value: 'S',
        label: 'Si'
      }, {
        value: 'N',
        label: 'No'
      }
      ],
      showDialog: false
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['getArea']),
    ...mapGetters(['isLoggedIn'])
  },
  methods: {
    async categoriaValid () {
      this.ticket.usrSttickSubcat = ''
      await this.getSubCategorias(this, this.ticket.usrSttickCategoria)
      this.subCategorias = JSON.parse(localStorage.getItem('subCategorias'))
    },
    async saveChanges () {
      var self = this
      this._additemTareas(self)
      self.ticket.fases[0].usrFasesFechainicio = this.ticket.usrSttickFechaAlta
      self.asignados.forEach(function (element) {
        if (self.getToken.user === element.usrlogin) {
          self.ticket.fases[0].usrFasesProf = element.value
        }
      })
      var pUrl = 'http://' + config.ip + '/ticket/Sttick'
      this.ticket.usrSttickFchord = this.ticket.usrSttickFchord.indexOf('T') > -1 ? this.ticket.usrSttickFchord : this.ticket.usrSttickFchord.concat('T00:00:00Z[-03:00]')
      if (this.ticket.usrSttickFechaAlta instanceof Date) {
        this.ticket.usrSttickFechaAlta = this.ticket.usrSttickFechaAlta.toISOString().substring(0, 10)
      }
      this.ticket.usrSttickFechaAlta = this.ticket.usrSttickFechaAlta.substring(0, 10).concat('T00:00:00Z[-03:00]')

      this.ticket.usrSttickFechaentrega = this.ticket.usrSttickFechaentrega.replace('T00:00:00Z[-03:00]', '').concat('T00:00:00Z[-03:00]')
      this.ticket.usrSttickFechaFin = this.ticket.usrSttickFechaFin.indexOf('T') > -1 ? this.ticket.usrSttickFechaFin : this.ticket.usrSttickFechaFin.concat('T00:00:00Z[-03:00]')
      this.ticket.fases.forEach(function (item) {
        if (!item.usrFasesFechainicio) {
          item.usrFasesFechainicio = '1900-01-01T00:00:00Z[-03:00]'
        }
        if (!item.usrFasesFechafin) {
          item.usrFasesFechafin = '1900-01-01T00:00:00Z[-03:00]'
        }
        if (item.usrFasesFechainicio instanceof Date) {
          item.usrFasesFechainicio = item.usrFasesFechainicio.toISOString().substring(0, 10)
        }
        if (item.usrFasesFechafin instanceof Date) {
          item.usrFasesFechafin = item.usrFasesFechafin.toISOString().substring(0, 10)
        }
        item.usrFasesFechainicio = item.usrFasesFechainicio.substring(0, 10).concat('T00:00:00Z[-03:00]')
        item.usrFasesFechafin = item.usrFasesFechafin.substring(0, 10).concat('T00:00:00Z[-03:00]')
      })
      this.ticket.resueltos.forEach(function (item) {
        if (!item.usrResueltoFechainicio) {
          item.usrResueltoFechainicio = '1900-01-01T00:00:00Z[-03:00]'
        }
        if (!item.usrResueltoFechafin) {
          item.usrResueltoFechafin = '1900-01-01T00:00:00Z[-03:00]'
        }
        if (item.usrResueltoFechainicio instanceof Date) {
          item.usrResueltoFechainicio = item.usrResueltoFechainicio.toISOString().substring(0, 10)
        }
        if (item.usrResueltoFechafin instanceof Date) {
          item.usrResueltoFechafin = item.usrResueltoFechafin.toISOString().substring(0, 10)
        }
        item.usrResueltoFechainicio = item.usrResueltoFechainicio.substring(0, 10).concat('T00:00:00Z[-03:00]')
        item.usrResueltoFechafin = item.usrResueltoFechafin.substring(0, 10).concat('T00:00:00Z[-03:00]')
      })
      this.ticket.esperas.forEach(function (item) {
        item.usrEsperasFchrecl = item.usrEsperasFchrecl || '1900-01-01'
        item.usrEsperasFechainicio = item.usrEsperasFechainicio || '1900-01-01'
        item.usrEsperasFechafin = item.usrEsperasFechafin || '1900-01-01'
        item.usrEsperasFchrecl = item.usrEsperasFchrecl.substring(0, 10).concat('T00:00:00Z[-03:00]')
        item.usrEsperasFechainicio = item.usrEsperasFechainicio.substring(0, 10).concat('T00:00:00Z[-03:00]')
        item.usrEsperasFechafin = item.usrEsperasFechafin.substring(0, 10).concat('T00:00:00Z[-03:00]')
      })
      this.ticket.tags.forEach(function (item) {
        if (!item.usrTagsFecha) {
          item.usrTagsFecha = '1900-01-01T00:00:00Z[-03:00]'
        }
        if (item.usrTagsFecha instanceof Date) {
          item.usrTagsFecha = item.usrTagsFecha.toISOString().substring(0, 10)
        }
        item.usrTagsFecha = item.usrTagsFecha.substring(0, 10).concat('T00:00:00Z[-03:00]')
      })
      this.ticket.minutas.forEach(function (item) {
        item.usrMinutaFecha = item.usrMinutaFecha.indexOf('T') > -1 ? item.usrMinutaFecha : item.usrMinutaFecha.concat('T00:00:00Z[-03:00]')
      })
      this.ticket.imputaciones.forEach(function (item) {
        item.usrImpHsiImpHsFch = item.usrImpHsiImpHsFch.indexOf('T') > -1 ? item.usrImpHsiImpHsFch : item.usrImpHsiImpHsFch.concat('T00:00:00Z[-03:00]')
      })
      var pBody = JSON.stringify(this.ticket)
      await this.persist(pUrl, 'POST', pBody)
      this.$router.push('/?ticket=' + JSON.parse(localStorage.getItem('newticket')).usrSttickNroticket)
    }
  }
}
</script>
<style>
  .el-input__inner {
    color: red important;
  }
  span {
    white-space: pre-line;
  }
  .align-right {
    text-align: center;
  }
</style>
