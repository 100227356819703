<template>
<div v-if="isLoggedIn" class="container-fluid">
  <back/>
  <form class = 'form-inline' role='form' @submit.prevent='getTop5P2'>
    <br/>
    <div class='row'>
      <div class="col-sm-2">
        <el-select
          v-model="asignado"
          filterable
          clearable
          placeholder="Asignado"
          @change="cargarFechas"
        >
          <el-option
            v-for="item in asignados"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-sm-2">
        <el-select
          v-model="fecha"
          filterable
          clearable
          placeholder="Fechas"
          @change="getTop5P2"
        >
          <el-option
            v-for="item in top5Fechas"
            :key="item.fecha"
            :label="item.fecha"
            :value="item.fecha"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5">
        <PieChart v-bind:chartData='chartState.chartData' v-bind:chartOptions='chartState.chartOptions' />
      </div>
      <div class="col-md-1 d-flex align-items-top">
          <el-tag class="ml-2" type="info">
            <strong> {{this.top5 + this.recurrente + this.noprogramado}} hs</strong>
          </el-tag>
      </div>
      <div class="col-md-6">
        <div>
          <table id='table_id'
                class='table table-borderless table-condensed'
                >
            <thead class="t5">
              <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>Top5</strong></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{this.top5}}</td>
              </tr>
              <tr v-for='(imp, i) in top5part2' :key='i'>
                <td>{{ i + 1 }}</td>
                <td>
                  <el-tag v-if="imp.estado=='S'">Asignado</el-tag>
                  <el-tag v-if="imp.estado=='F'">Cerrado</el-tag>
                  <el-tag type="success" v-if="imp.estado=='C'">En curso</el-tag>
                  <el-tag type="info" v-if="imp.estado=='P'">Sin procesar</el-tag>
                  <el-tag type="warning" v-if="imp.estado=='D'">Demorado</el-tag>
                  <el-tag type="danger" v-if="imp.estado=='U'">Bloqueado</el-tag>
                </td>
                <td> <router-link v-bind:to="'/?ticket='+imp.descripcion.toString().split('-')[0].trim()">{{imp.descripcion.toString().split('-')[0].trim()}}</router-link> </td>
                <td v-html="imp.descripcion.toString().split('-')[1].trim()"></td>
                <td v-if="imp.horas!=0" v-html="imp.horas"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </form>
  <el-divider></el-divider>
  <div class="row">
    <div class="col-md-6">
      <table id='table_id'
              class='table table-borderless table-condensed'
              >
          <thead class="recurrente">
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><strong>Recurrentes</strong></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{this.recurrente}}</td>
            </tr>
            <tr v-for='(imp, i) in top5p4recurrentes' :key='i'>
              <td>{{ i + 1 }}</td>
              <td>
                <el-tag v-if="imp.descripcion.substring(0, 1)=='S'">Asignado</el-tag>
                <el-tag v-if="imp.descripcion.substring(0, 1)=='F'">Cerrado</el-tag>
                <el-tag type="success" v-if="imp.descripcion.substring(0, 1)=='C'">En curso</el-tag>
                <el-tag type="info" v-if="imp.descripcion.substring(0, 1)=='P'">Sin procesar</el-tag>
                <el-tag type="warning" v-if="imp.descripcion.substring(0, 1)=='D'">Demorado</el-tag>
                <el-tag type="danger" v-if="imp.descripcion.substring(0, 1)=='U'">Bloqueado</el-tag>
              </td>
              <td> <router-link v-bind:to="'/?ticket='+imp.descripcion.toString().split('-')[1].trim()">{{imp.descripcion.toString().split('-')[1].trim()}}</router-link> </td>
                <td v-html="imp.descripcion.toString().split('-')[2].trim()"></td>
              <td v-html="imp.horas"></td>
            </tr>
          </tbody>
        </table>
      </div>
    <div class="col-md-6">
      <table id='table_id'
              class='table table-borderless table-condensed'
              >
          <thead class="noprog">
            <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td><strong>No programado</strong></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{this.noprogramado}}</td>
            </tr>
            <tr v-for='(imp, i) in top5p4noprog' :key='i'>
              <td>{{ i + 1 }}</td>
              <td>
                <el-tag v-if="imp.descripcion.substring(0, 1)=='S'">Asignado</el-tag>
                <el-tag v-if="imp.descripcion.substring(0, 1)=='F'">Cerrado</el-tag>
                <el-tag type="success" v-if="imp.descripcion.substring(0, 1)=='C'">En curso</el-tag>
                <el-tag type="info" v-if="imp.descripcion.substring(0, 1)=='P'">Sin procesar</el-tag>
                <el-tag type="warning" v-if="imp.descripcion.substring(0, 1)=='D'">Demorado</el-tag>
                <el-tag type="danger" v-if="imp.descripcion.substring(0, 1)=='U'">Bloqueado</el-tag>
              </td>
              <td> <router-link v-bind:to="'/?ticket='+imp.descripcion.toString().split('-')[1].trim()">{{imp.descripcion.toString().split('-')[1].trim()}}</router-link> </td>
                <td v-html="imp.descripcion.toString().split('-')[2].trim()"></td>
              <td v-html="imp.horas"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  <el-divider></el-divider>
</div>
<el-dialog
  title="Detalles"
  v-model="dialogVisible"
  width="50%"
>
  <span>
    <p p align="justify" style='white-space: pre-line'>
    {{dialogo}}
    </p>
  </span>
</el-dialog>
</template>
<script>
import PieChart from '../components/PieChart.vue'
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
import router from '../router'
import shared from '../shared/shared'
import back from '../components/back.vue'
export default {
  extends: shared,
  props: {
    pprof: String
  },
  components: {
    back, PieChart
  },
  created: function () {
  },
  mounted: async function () {
    const self = this
    await this.getProfesionals(self)
    // window.alert(self.$route.query.pprof)
    if (self.$route.query.pprof) {
      // el user
      self.asignados.forEach(function (element) {
        if (self.getToken.user === element.usrlogin) {
          self.asignado = element.value
        }
      })
      this.fecha = ''
      this.$router.replace('/Top5')
    } else {
      this.asignado = localStorage.getItem('asignado')
      this.fecha = localStorage.getItem('fecha')
    }
    self.cargarFechas()
    document.title = 'Top5'
  },
  watch: {
    asignado (newVal) {
      localStorage.setItem('asignado', newVal)
    },
    fecha (newVal) {
      localStorage.setItem('fecha', newVal)
    },
    search (newVal) {
      localStorage.setItem('searchImp', newVal)
    }
  },
  data: function () {
    return {
      chartState: {
        chartData: {
          datasets: [
            {
              data: [],
              backgroundColor: []
            }
          ],
          labels: []
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top'
            },
            title: {
              display: true,
              text: 'Chart.js Doughnut Chart'
            }
          }
        }
      },
      top5: 0,
      recurrente: 0,
      noprogramado: 0,
      fecha: '',
      top5fechas: [],
      top5part2: [],
      top5part3: [],
      top5part4: [],
      asignados: [],
      asignado: '',
      activeHeaderName: '1',
      isLoaded: false,
      impAreaEmp: [],
      categoria: '',
      categorias: [
        {
          value: 'A',
          label: 'Asistencia'
        }, {
          value: 'R',
          label: 'Requisito'
        },
        {
          value: 'F',
          label: 'Falla'
        }],
      area: '',
      areas: [
        {
          value: 'ADM',
          label: 'Administracion Central'
        },
        {
          value: 'AGRB',
          label: 'Agro brandsen'
        },
        {
          value: 'AGRT',
          label: 'Agro tandil'
        },
        {
          value: 'BC',
          label: 'BLUE-C'
        },
        {
          value: 'CE',
          label: 'ACTIVA - Consultores Externos'
        },
        {
          value: 'COM',
          label: 'Compras'
        },
        {
          value: 'CONT',
          label: 'Contabilidad e Impuestos'
        },
        {
          value: 'DIR',
          label: 'Direccion'
        },
        {
          value: 'EESS',
          label: 'Estacion Varela'
        },
        {
          value: 'GERA',
          label: 'Gerencia Administracion'
        },
        {
          value: 'GERAGR',
          label: 'Gerencia Agro'
        },
        {
          value: 'GERM',
          label: 'Gerencia Mantenimiento'
        },
        {
          value: 'GERT',
          label: 'Gerencia Transporte'
        },
        {
          value: 'IT',
          label: 'Tecnologia Informatica'
        },
        {
          value: 'LOGI',
          label: 'Logistica'
        },
        {
          value: 'RRHH',
          label: 'Recursos Humanos'
        },
        {
          value: 'TALL',
          label: 'Taller'
        },
        {
          value: 'TESO',
          label: 'Tesoreria'
        }
      ],
      estados: [{
        value: 'NA',
        label: 'No Aplica'
      }, {
        value: 'A',
        label: 'Abiertos'
      }, {
        value: 'P',
        label: 'Sin Procesar'
      }, {
        value: 'S',
        label: 'Asignado'
      }, {
        value: 'D',
        label: 'Demorado'
      }, {
        value: 'U',
        label: 'Bloqueado'
      }, {
        value: 'C',
        label: 'En curso'
      }, {
        value: 'F',
        label: 'Finalizado'
      }],
      estado: '',
      colaboradores: [{
        value: 'NA',
        label: 'No Aplica'
      }, {
        value: 'S',
        label: 'Si'
      }, {
        value: 'N',
        label: 'No'
      }],
      chartOptions_p: [],
      chartOpt_consult_p: [],
      dataZonas_p: [],
      impEmp_p: [],
      impArea_p: [],
      impConsultor_p: [],
      colaborador: '',
      dialogVisible: false,
      date1: null,
      date2: null,
      value1: '',
      search: '',
      impu: [],
      areaF: true,
      ticketF: true,
      responsableF: true,
      descripcionF: true,
      shortcuts: [{
        text: '1 Week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        }
      }, {
        text: '2 Weeks',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 14)
          return [start, end]
        }
      }, {
        text: '1 Month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        }
      }, {
        text: '3 Months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        }
      }]
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn']),
    filteredImpu () {
      return this.impu
        .filter(this.filterByCategoria)
        .filter(this.filterByArea)
        .filter(this.filterByExterno)
        .filter(this.filterByProf)
        .filter(this.filterByStatus)
        .sort((a, b) => a.horas > b.horas ? -1 : 1)
    },
    filteredImpuv2 () {
      return this.impAreaEmp
        .filter(this.filterByCategoria)
        .filter(this.filterByArea)
        .filter(this.filterByExterno)
        .filter(this.filterByProf)
        .filter(this.filterByStatus)
        .sort((a, b) => a.horas > b.horas ? -1 : 1)
    },
    top5p4recurrentes () {
      return this.top5part4
        .filter(this.filterCatRec)
    },
    top5p4noprog () {
      return this.top5part4
        .filter(this.filterCatNoProg)
    }
  },
  methods: {
    filterCatRec (item) {
      if (item.tag === 'recurrente') {
        return true
      }
    },
    filterCatNoProg (item) {
      if (item.tag === 'no programado') {
        return true
      }
    },
    cargarFechas: async function (e) {
      var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/ticket/imputacion/top5/params/params;prof=' + self.asignado + ';fecha=' + self.fecha + ';part=0'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('top5Fechas', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.top5Fechas = JSON.parse(localStorage.getItem('top5Fechas'))
          // self.fecha = ''
          self.getTop5P2()
          // self.closeFSL()
        })
        .catch(function (error) {
          self.fecha = ''
          console.log(error)
          // self.closeFSL()
        })
    },
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL () {
      this.loading.close()
    },
    sum () {
      var res = 0
      this.filteredImpu.forEach(function (item) {
        res = res + item.horas
      })
      return Math.round(res * 100) / 100
    },
    sumv2 () {
      var res = 0
      this.filteredImpuv2.forEach(function (item) {
        res = res + item.horas
      })
      return Math.round(res * 100) / 100
    },
    highlightMatches (text) {
      if (!text) return
      const matchExists = text
        .toLowerCase()
        .includes(this.search.toLowerCase())
      if (!matchExists) return text
      const re = new RegExp(this.search, 'ig')
      return text.replace(re, matchedText => `<strong>${matchedText}</strong>`)
    },
    filterByCategoria (imp) {
      var filter1 = this.categoria || 'NA'
      if ((this.categoria === imp.categoria || filter1 === 'NA')) {
        return true
      }
      return false
    },
    filterByArea (imp) {
      var filter1 = this.area || 'NA'
      if ((this.area === imp.area || filter1 === 'NA')) {
        return true
      }
      return false
    },
    filterByExterno (imp) {
      console.log('colaborador:' + imp.externo)
      var colaborador1 = this.colaborador || 'NA'
      if ((this.colaborador === imp.externo || colaborador1 === 'NA')) {
        console.log('colaborador: true')
        return true
      }
      console.log('colaborador: false')
      return false
    },
    filterByProf (imp) {
      if (this.search.length === 0) {
        return true
      }
      if (this.responsableF.toString().toLowerCase() === 'false' & this.ticketF.toString().toLowerCase() === 'false') {
        return true
      }
      return ((imp.ticket.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.ticketF) || (imp.profesional.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.responsableF))
    },
    filterByStatus (imp) {
      return imp.estado.toString().toLowerCase().indexOf(this.estado.toLowerCase()) > -1
    },
    getTop5P2: function (e) {
      var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/ticket/imputacion/top5/params/params;prof=' + self.asignado + ';fecha=' + self.fecha + ';part=2'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('top5part2', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.top5part2 = JSON.parse(localStorage.getItem('top5part2'))
          self.getTop5P3()
          self.getTop5P3()
          // self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    getTop5P3: function (e) {
      var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/ticket/imputacion/top5/params/params;prof=' + self.asignado + ';fecha=' + self.fecha + ';part=3'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('top5part3', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.top5part3 = JSON.parse(localStorage.getItem('top5part3'))
          self.top5 = 0
          self.recurrente = 0
          self.noprogramado = 0
          self.top5part3.forEach(function (item) {
            switch (item.tag.toLowerCase()) {
              case 'top5':
                self.top5 = item.horas
                break
              case 'recurrente':
                self.recurrente = item.horas
                break
              case 'no programado':
                self.noprogramado = item.horas
                break
            }
          })
          self.chartState.chartData = {
            datasets: [
              {
                data: [self.top5, self.recurrente, self.noprogramado],
                backgroundColor: [
                  'rgb(54, 162, 235)', 'rgb(255, 205, 86)', 'rgb(255, 99, 132)'
                ]
              }
            ],
            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ['Top5', 'Recurrentes', 'no programadas']
          }
          self.getTop5P4()
          // self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    getTop5P4: function (e) {
      var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/ticket/imputacion/top5/params/params;prof=' + self.asignado + ';fecha=' + self.fecha + ';part=4'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('top5part4', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.top5part4 = JSON.parse(localStorage.getItem('top5part4'))
          // self.closeFSL()
          self.isLoaded = true
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    getTop5_V2: function (e) {
      var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/ticket/imputacion/summarized.v2/params/params;from=' + self.strToDate(self.value1[0]) + ';to=' + self.strToDate(self.value1[1])
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('imputacionSumV2', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.impAreaEmp = JSON.parse(localStorage.getItem('imputacionSumV2'))
          // self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    strToDate (str) {
      var date = new Date(str)
      var mnth = ('0' + (date.getMonth() + 1)).slice(-2)
      var day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear(), mnth, day].join('')
    }
  }
}
</script>
<style scoped>
  .recurrente {
    background: rgb(255, 205, 86);
  }
  .t5 {
    background: rgb(54, 162, 235);
  }
  .noprog {
    background: rgb(255, 99, 132);
  }
</style>
