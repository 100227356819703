import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import ElementPlus from 'element-plus'
import Unicon from 'vue-unicons'
import { uniPaperclip } from 'vue-unicons/dist/icons'
import 'element-plus/dist/index.css'
import 'element-theme-dark'
import VueApexCharts from 'vue3-apexcharts'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import AltaTktCmp from './components/AltaTktCmp.vue'
import Borrador from './components/Borrador.vue'
Unicon.add([uniPaperclip])
const app = createApp(App)
app.use(Unicon)
app.use(store)
app.use(router)
app.component('AltaTktCmp', AltaTktCmp)
app.component('Borrador', Borrador)
app.use(ElementPlus)
app.use(VueApexCharts)
app.use(ElementPlusIconsVue)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.mount('#app')
