<template>
<div v-if="isLoggedIn" class="container-fluid">
  <back/>
  <form class = 'form-inline' role='form' @submit.prevent='getImpu'>
    <br/>
    <div class="row">
      <div class="col-md-3">
      </div>
      <div class="col-md-4">
        <span style='padding-right: 10px;'>Rango de fechas</span>
        <el-date-picker
          v-model="value1"
          type="daterange"
          unlink-panels
          range-separator="|"
          start-placeholder="Desde"
          end-placeholder="Hasta"
        >
        </el-date-picker>
      </div>
      <div class="col-md-1">
        <button type='submit' class='btn btn-primary mb-2'>
          Buscar
        </button>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h2>Disponibilidad %</h2>
          <apexchart
            type="bar"
            :options="chartOptions"
            :series="dataDispo"
            v-if="isLoaded"
            height="300"
          ></apexchart>
        </div>
        <div class="col-md-6">
          <h2>Sinergia %</h2>
          <apexchart
            type="bar"
            :options="chartOptions"
            :series="dataSinergia"
            v-if="isLoaded"
            height="300"
          ></apexchart>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h2>Cargas diarias en PH</h2>
            <apexchart
              type="bar"
              :options="chartOptions"
              :series="dataPH"
              v-if="isLoaded"
              height="300"
            ></apexchart>
        </div>
        <div class="col-md-6">
          <h2>Tiempo de Ciclo</h2>
          <apexchart
            type="bar"
            :options="cicloChartOptions"
            :series="dataCiclo"
            v-if="isLoaded"
            height="300"
          ></apexchart>
        </div>
      </div>
      <div class="col-md-4">
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
      </div>
      <div class="col-md-4">
          Ultima actualizacion: {{updated}}
      </div>
    </div>
  </form>
  <br/>
  <div class='row'>
    <el-collapse v-model="activeHeaderName" v-if="false" accordion>
      <el-collapse-item title="Cargas en PH" name="1">
        <div id='message' div class='col-md-12'>
          <el-input type='text' v-model='searchCargas' placeholder= 'Filtrar por...' />
          <div>
            <table id='table_id'
                  class='table table-bordered table-condensed vue-table'
                  >
              <thead>
                <tr class="blue">
                    <th>Zona</th>
                    <th>Fecha</th>
                    <th>Cargas</th>
                </tr>
                <tr class="gray">
                <td><strong>Resumen</strong></td>
                <td></td>
                <td><strong></strong></td>
                </tr>
              </thead>
              <tbody>
                <tr v-for='(item, i) in filteredCargas' :key='i'>
                  <td>{{item.zona}}</td>
                  <td>{{item.fecha}}</td>
                  <td>{{item.cargas}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="Ciclos" name="2">
        <div id='message' div class='col-md-12'>
          <!-- <el-input type='text' v-model='searchCargas' placeholder= 'Filtrar por...' /> -->
          <div>
            <table id='table_id'
                  class='table table-bordered table-condensed table-striped vue-table'
                  >
              <thead>
                <tr class="blue">
                    <th>Zona</th>
                    <th>Unidad</th>
                    <th>Tiempo de Ciclo</th>
                </tr>
                <tr class="gray">
                <td><strong>Resumen</strong></td>
                <td></td>
                <td><strong>{{sumCargas()}}</strong></td>
                </tr>
              </thead>
              <tbody>
                <tr v-for='(item, i) in filteredCiclos' :key='i'>
                  <td>{{item.zona}}</td>
                  <td>{{item.unidad}}</td>
                  <td>{{item.ciclo}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
import router from '../router'
import shared from '../shared/shared'
import back from '../components/back.vue'

export default {
  components: {
    back
  },
  extends: [shared],
  mounted: function () {
    window.setInterval(() => {
      this.getImpu()
    }, 5 * 60 * 1000)
    if (!this.isLoggedIn) {
      this.errorMsg('Para continuar debe loggearse')
    }
    var self = this
    if (localStorage.getItem('dispo')) {
      try {
        self.dispo = JSON.parse(localStorage.getItem('dispo'))
      } catch (e) {
        localStorage.removeItem('dispo')
      }
    }
    if (localStorage.getItem('cargas')) {
      try {
        self.cargas = JSON.parse(localStorage.getItem('cargas'))
      } catch (e) {
        localStorage.removeItem('cargas')
      }
    }
    if (localStorage.getItem('ciclos')) {
      try {
        self.ciclos = JSON.parse(localStorage.getItem('ciclos'))
      } catch (e) {
        localStorage.removeItem('ciclos')
      }
    }
    if (localStorage.getItem('sinergia')) {
      try {
        self.sinergia = JSON.parse(localStorage.getItem('sinergia'))
      } catch (e) {
        localStorage.removeItem('sinergia')
      }
    }
    if (localStorage.getItem('unidadF')) {
      self.unidadF = localStorage.getItem('unidadF')
    }
    if (localStorage.getItem('choferF')) {
      self.choferF = localStorage.getItem('choferF')
    }
    if (localStorage.getItem('searchLarga')) {
      self.search = localStorage.getItem('searchLarga')
    }
    if (localStorage.getItem('searchcargas')) {
      self.searchcargas = localStorage.getItem('searchcargas')
    }
    if (localStorage.getItem('fromlarga')) {
      self.value1 = [localStorage.getItem('fromlarga'), localStorage.getItem('toLarga')]
    }
    self.isLoaded = true
    this.updateChart()
  },
  watch: {
    value1 (newVal) {
      localStorage.setItem('fromlarga', newVal[0])
      localStorage.setItem('toLarga', newVal[1])
    },
    search (newVal) {
      localStorage.setItem('searchLarga', newVal)
    },
    searchCargas (newVal) {
      localStorage.setItem('searchCargas', newVal)
    },
    unidadF (newVal) {
      localStorage.setItem('unidadF', newVal)
    },
    choferF (newVal) {
      localStorage.setItem('choferF', newVal)
    }
  },
  data: function () {
    return {
      updated: '',
      loading: '',
      isLoaded: false,
      chartOptions: [],
      cicloChartOptions: [],
      dataDispo: [],
      dataSinergia: [],
      dataPH: [],
      data: [],
      dataCiclo: [],
      labels: [],
      datasets: [],
      colaboradores: [{
        value: 'NA',
        label: 'No Aplica'
      }, {
        value: 'S',
        label: 'Si'
      }, {
        value: 'N',
        label: 'No'
      }],
      colaborador: '',
      unidadF: true,
      choferF: true,
      date1: null,
      date2: null,
      value1: '',
      search: '',
      searchCargas: '',
      dispo: [],
      cargas: [],
      ciclos: [],
      sinergia: [],
      areaF: true,
      ticketF: true,
      responsableF: true,
      descripcionF: true,
      shortcuts: [{
        text: '1 Week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        }
      }, {
        text: '2 Weeks',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 14)
          return [start, end]
        }
      }, {
        text: '1 Month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        }
      }, {
        text: '3 Months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        }
      }]
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn']),
    filteredImpu () {
      return this.impu
        .filter(this.filterByProf)
    },
    filteredCargas () {
      return this.cargas
        .filter(this.filterByNothing)
    },
    filteredCiclos () {
      return this.ciclos
        .filter(this.filterByNothing)
    }
  },
  methods: {
    updateChart () {
      console.log('updatechart called')
      var self = this
      self.dataPH = []
      self.dataDispo = []
      self.dataSinergia = []

      var yGoalsDispo = []
      var yDispo = []
      this.dispo.forEach(function (item) {
        if (item) {
          yDispo.push(item.porcentaje)
          if (item.objetivo !== 0) {
            yGoalsDispo.push(item.objetivo)
          } else {
            yGoalsDispo.push(null)
          }
        }
      })
      self.dataDispo = [
        {
          data: yDispo,
          type: 'column',
          name: 'REAL'
        },
        {
          data: yGoalsDispo,
          type: 'column',
          name: 'IDEAL'
        }
      ]
      var yGoalsSinergia = []
      var ySinergia = []
      this.sinergia.forEach(function (item) {
        if (item) {
          ySinergia.push(item.sinergia)
          if (item.objetivo !== 0) {
            yGoalsSinergia.push(item.objetivo)
          } else {
            yGoalsSinergia.push(null)
          }
        }
      })
      self.dataSinergia = [
        {
          data: ySinergia,
          name: 'REAL'
        },
        {
          data: yGoalsSinergia,
          name: 'IDEAL'
        }
      ]

      var yGoalsCiclos = []
      var yCiclos = []
      var xCiclos = []
      this.ciclos.forEach(function (item) {
        if (item) {
          yCiclos.push(item.ciclo)
          if (item.objetivo !== 0) {
            yGoalsCiclos.push(item.objetivo)
          } else {
            yGoalsCiclos.push(null)
          }
          xCiclos.push(item.fecha)
        }
      })
      self.dataCiclo = [
        {
          data: yCiclos,
          type: 'bar',
          name: 'REAL'
        },
        {
          data: yGoalsCiclos,
          type: 'bar',
          name: 'IDEAL'
        }
      ]
      var yGoalsPH = []
      var yPH = []
      var xPH = []
      this.cargas.forEach(function (item) {
        if (item) {
          yPH.push(item.cargas)
          if (item.objetivo !== 0) {
            yGoalsPH.push(item.objetivo)
          } else {
            yGoalsPH.push(null)
          }
          xPH.push(item.fecha)
        }
      })
      self.dataPH = [
        {
          data: yPH,
          name: 'REAL'
        },
        {
          data: yGoalsPH,
          name: 'IDEAL'
        }
      ]
      self.chartOptions = {
        xaxis: {
          categories: xPH,
          labels: {
            rotate: -45,
            // rotateAlways: true,
            style: {
              colors: 'white',
              useSeriesColors: false,
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 420,
              cssClass: 'apexcharts-yaxis-label'
            }
          }//,
          // tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: '',
            rotate: -90
          }
        },
        dataLabels: {
          // enabled: true,
          style: {
            colors: ['lightgray', 'black'],
            fontSize: '20px'
          }
        },
        legend: {
          labels: {
            colors: 'lightgray',
            useSeriesColors: false
          }
        }
      }
      self.cicloChartOptions = {
        xaxis: {
          categories: xCiclos,
          labels: {
            rotate: -45,
            // rotateAlways: true,
            style: {
              colors: 'white',
              useSeriesColors: false,
              fontSize: '16px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 420,
              cssClass: 'apexcharts-yaxis-label'
            }
          }//,
          // tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: '',
            rotate: -90
          }
        },
        dataLabels: {
          // enabled: true,
          style: {
            colors: ['lightgray', 'black'],
            fontSize: '20px'
          }
        },
        legend: {
          labels: {
            colors: 'lightgray',
            useSeriesColors: false
          }
        }
      }
      var today = new Date()
      self.updated = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear() + ' ' + today.getHours() + ':' + ('00' + today.getMinutes()).substring(('00' + today.getMinutes()).length - 2)
    },
    errorMsg (val) {
      this.$message({
        message: val,
        type: 'error'
      })
    },
    sum () {
      var res = 0
      this.filteredImpu.forEach(function (item) {
        res = res + item.km
      })
      return this.numberFormat(Math.round(res * 100) / 100)
    },
    sumCargas () {
      var res = 0
      this.filteredCargas.forEach(function (item) {
        res = res + item.cargas
      })
      return this.numberFormat(Math.round(res * 100) / 100)
    },
    highlightMatches (text) {
      if (!text) return
      const matchExists = text
        .toLowerCase()
        .includes(this.search.toLowerCase())
      if (!matchExists) return text
      const re = new RegExp(this.search, 'ig')
      return text.replace(re, matchedText => `<strong>${matchedText}</strong>`)
    },
    filterByProf (imp) {
      if (this.search.length === 0) {
        return true
      }
      // return true
      return ((imp.unidad.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.unidadF) ||
              (imp.chofer.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.choferF))
    },
    filterByNothing (imp) {
      return true
    },
    getImpu: function (e) {
      var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/comcam/4v/fromto/fromto;from=' + self.strToDate(self.value1[0]) + ';to=' + self.strToDate(self.value1[1]) + ';zone=M1;opcion='
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('dispo', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.dispo = JSON.parse(localStorage.getItem('dispo'))
          // self.closeFSL()
          self.updateChart()
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
      this.getCiclos()
      this.getCargas()
      this.getSinergia()
    },
    getCargas: function (e) {
      var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/comcam/usr_grtvii/fromto/fromto;from=' + self.strToDate(self.value1[0]) + ';to=' + self.strToDate(self.value1[1]) + ';zone=M1;opcion=M'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('cargas', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.cargas = JSON.parse(localStorage.getItem('cargas'))
          // self.closeFSL()
          self.updateChart()
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    getCiclos: function (e) {
      var self = this
      // self.openFSL()
      const url = 'http://' + config.ip + '/comcam/usr_grtvii/ciclos/fromto/fromto;from=' + self.strToDate(self.value1[0]) + ';to=' + self.strToDate(self.value1[1]) + ';zone=M1;opcion=M'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('ciclos', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.ciclos = JSON.parse(localStorage.getItem('ciclos'))
          // self.closeFSL()
          self.updateChart()
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    getSinergia: function (e) {
      var self = this
      const url = 'http://' + config.ip + '/comcam/usr_grtvii/sinergia/fromto/fromto;from=' + self.strToDate(self.value1[0]) + ';to=' + self.strToDate(self.value1[1]) + ';zone=M1;opcion=M'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('sinergia', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.sinergia = JSON.parse(localStorage.getItem('sinergia'))
          // self.closeFSL()
          self.updateChart()
          self.isLoaded = true
        })
        .catch(function (error) {
          console.log(error)
          // self.closeFSL()
        })
    },
    strToDate (str) {
      var date = new Date(str)
      var mnth = ('0' + (date.getMonth() + 1)).slice(-2)
      var day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear(), mnth, day].join('')
    },
    strToHour (str) {
      var ret = str.substring(0, 2) + ':'
      ret = ret + str.substring(2, 4)
      return ret
    },
    numberFormat (val) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0
      })
      return formatter.format(val)
    }
  }
}
</script>
