<template>
  <body v-on:keyup.ctrl.shift.f="focusInput">
   <div v-if="isLoggedIn" class="container-fluid">
    PROYECTOS
    <!-- <AltaTktCmp ref="altatkt"/>
    <button @click= "this.$refs.altatkt.showDialog=true" class="btn btn-primary mb-2">+</button>
    <br/> -->
    <back/>
    <br/>
    <form class="form-inline" role="form" @submit.prevent="this.getTicket">
      <div class="row g-2">
        <div class="col-sm"></div>
        <div class="col-sm">
          <el-input id="tkt" v-model="seek" placeholder="ingrese palabra clave" />
        </div>
        <div class="col-sm">
          <button type="submit" class="btn btn-primary mb-2">Buscar</button>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-md-1">
        <!-- Orden usuario -->
        <el-select v-model="prioridad" filterable clearable placeholder="Prioridad">
          <el-option
            v-for="item in prioridades"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-md-1">
        <!-- Estado -->
        <el-select v-model="estado" filterable clearable placeholder="Estado">
          <el-option
            v-for="item in estados"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-md-2">
        <!-- Empresa -->
        <el-select v-model="empresa" filterable clearable placeholder="Empresa">
          <el-option
            v-for="item in empresas"
            :key="item.codigo"
            :label="item.codigo"
            :value="item.codigo"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-md-2">
        <!-- Colaborador -->
        <el-select
          v-model="asignado"
          filterable
          clearable
          placeholder="Asignado"
        >
          <el-option
            v-for="item in asignados"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <input type="checkbox" v-model = 'pendiente'>c/pend
        <input type="checkbox" v-model = 'exclusivo'>excl
      </div>
      <div class="col-md-2">
        <!-- Usuario -->
        <el-select v-model="usuario" filterable clearable placeholder="Usuario">
          <el-option
            v-for="item in usuarios"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-md-2">
        <!-- Departamento -->
        <el-select
          v-model="departamento"
          filterable
          clearable
          placeholder="Departamento"
          :disabled= "this.getArea.area != 'it' && this.getArea.area != 'DIR'"
        >
          <el-option
            v-for="item in departamentos"
            :key="item.codigo"
            :label="item.descripcion"
            :value="item.codigo"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-md-2">
        <!-- Recurrente -->
        <el-select v-model="recurrente" clearable placeholder="Recurrente" :disabled= "this.getArea.area != 'it' && this.getArea.area != 'DIR'">
          <el-option
            v-for="item in recurrentes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <el-divider>Resultado</el-divider>
    <div class="row">
      <div class="col-md-6">
        <div class="form-inline">
          <el-input
            placeholder="Filtrar por"
            v-model="search"
            id="isearch"
            ref="isearch"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="form-check-inline">
            <input type="checkbox" v-model="ticketF" label="Ticket"> Ticket
          </div>
          <div class="form-check-inline">
            <input type="checkbox" v-model="proyectoF" label="Proyecto"/> Proyecto
          </div>
          <div class="form-check-inline">
            <input type="checkbox" v-model="areaF" label="Departamento"/> Departamento
          </div>
          <div class="form-check-inline">
            <!-- <el-checkbox label="Descripcion" v-model="descripcionF" /> -->
            <input type="checkbox" v-model="descripcionF" label="Descripcion"/> Descripcion
          </div>
          <div class="form-check-inline">
            <!-- <el-checkbox label = "Responsable" v-model="responsableF" /> -->
            <input type="checkbox" v-model="responsableF" label = "Responsable"/> Responsable
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-10">
      </div>
      <div class="col-md-2">
        <el-button type="success" plain @click="downloadExcel(exportXLS)">
        Excel<el-icon class="el-icon--right"><Download /></el-icon>
        </el-button>
        <input type="checkbox" v-model="xlsdetallado" label="Detallado"/> Detallado
      </div>
    </div>
    <br />
    <div class="row">
      <div id="message" div class="col-md-12">
        <table
          id="table_id"
          data-filter-control="true"
          data-search="true"
          class="table table-borderless table-condensed "
        >
          <thead>
            <tr class="blue">
              <th>#</th>
              <th>Ticket</th>
              <th>Descripcion</th>
              <th @click="this.ordenarPor('proyecto')"><el-icon color="#409EFC" v-if="this.sortBy==='proyecto' && this.way==='up'"><SortUp /></el-icon><el-icon color="#409EFC" v-if="this.sortBy==='proyecto' && this.way==='down'"><SortDown /></el-icon>Proyecto</th>
              <!-- <th v-if="getArea.area === 'it'">Inversion</th> -->
              <th v-if="getArea.area === 'it'">Estimado</th>
              <th v-if="getArea.area === 'it'">Originales</th>
              <th v-if="getArea.area === 'it'">Revisiones</th>
              <th v-if="getArea.area === 'it'">Desvio</th>
              <th @click="this.ordenarPor('imputado')"><el-icon color="#409EFC" v-if="this.sortBy==='imputado' && this.way==='up'"><SortUp /></el-icon><el-icon color="#409EFC" v-if="this.sortBy==='imputado' && this.way==='down'"><SortDown /></el-icon>Imputado</th>
              <th v-if="getArea.area === 'it'">Saldo</th>
              <th>Fases</th>
              <th v-if="getArea.area === 'it'">Progreso</th>
              <th v-if="getArea.area !== 'it'">Demorado</th>
              <th v-if="getArea.area !== 'it'">Por</th>
              <th v-if="getArea.area !== 'it'">Observacion</th>
              <th>Area</th>
              <th>Responsable</th>
              <th @click="this.ordenarPor('alta')">Fecha <el-icon color="#409EFC" v-if="this.sortBy==='alta' && this.way==='up'"><SortUp /></el-icon><el-icon color="#409EFC" v-if="this.sortBy==='alta' && this.way==='down'"><SortDown /></el-icon>Alta</th>
              <th @click="this.ordenarPor('actividad')">Ultima <el-icon color="#409EFC" v-if="this.sortBy==='actividad' && this.way==='up'"><SortUp /></el-icon><el-icon color="#409EFC" v-if="this.sortBy==='actividad' && this.way==='down'"><SortDown /></el-icon>Actividad</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredTktV2" :key="item.USR_STTICK_NROTICKET">
              <td>{{ index + 1 }}</td>
              <td> <router-link v-bind:to="'/?ticket='+item.USR_STTICK_NROTICKET">{{item.USR_STTICK_NROTICKET}}</router-link> </td>
              <td v-html="highlightMatches(item.USR_DESCRIPCION_NROTKT)"></td>
              <td>{{item.USR_STTICK_PROYECTO}}</td>
              <!-- <td v-if="getArea.area === 'it'">$ {{this.numberFormat(item.usr_imputadas * 1700, 1)}}</td> -->
              <td v-if="getArea.area === 'it'">{{this.numberFormat(item.USR_HORAS, 1)}} </td>
              <td v-if="getArea.area === 'it'">{{this.numberFormat(item.usr_hs_originales, 1)}} </td>
              <td v-if="getArea.area === 'it'">{{this.numberFormat(item.usr_hs_revision, 1)}} </td>
              <td v-if="getArea.area === 'it'">{{this.numberFormat(item.usr_desvio_proy, 1)}} </td>
              <td>{{this.numberFormat(item.usr_imputadas, 1)}} </td>
              <td v-if="getArea.area === 'it'">{{this.numberFormat(item.USR_SALDOHS, 1)}}</td>
              <td>{{item.fases}}</td>
              <td v-if="getArea.area !== 'it'">
                {{item.usr_obses ? item.usr_obses.split("|")[0] + ' dias laborables' : ''}}
              </td>
              <td v-if="getArea.area !== 'it'">
                {{item.usr_obses ? item.usr_obses.split("|")[1]: ''}}
              </td>
              <td v-if="getArea.area !== 'it'">
                {{item.usr_obses ? item.usr_obses.split("|")[2]: ''}}
              </td>
              <td v-if="getArea.area === 'it'">
                <el-progress
                  v-if="item.USR_STTICK_ESTADO == 'C'"
                  :text-inside="false"
                  :stroke-width="10"
                  :percentage="item.USR_PROGRESO"
                  type="line"
                  status="success"
                >
                <template #default="{ percentage }">
                  <span class="percentage-value"><strong>{{ percentage }}</strong></span>
                </template>
                </el-progress>
                <el-progress
                  v-if="item.USR_STTICK_ESTADO == 'U'"
                  :text-inside="false"
                  :stroke-width="10"
                  :percentage="item.USR_PROGRESO"
                  type="line"
                  status="exception"
                ><template #default="{ percentage }">
                  <span class="percentage-value"><strong>{{ percentage }}</strong></span>
                  <span v-html="highlightMatches(item.usr_obses ? item.usr_obses.split('|')[0] + 'D | ' + item.usr_obses.split('|')[1] : '')"></span>
                </template>
                </el-progress>
                <el-progress
                  v-if="item.estado == 'D'"
                  :text-inside="false"
                  :stroke-width="10"
                  :percentage="item.USR_PROGRESO"
                  status="warning"
                ><template #default="{ percentage }">
                  <span class="percentage-value"><strong>{{ percentage }}%</strong></span>
                </template>
                </el-progress>
                <el-progress
                  v-if="item.estado == 'S'"
                  :text-inside="false"
                  :stroke-width="10"
                  :percentage="item.USR_PROGRESO"
                ><template #default="{ percentage }">
                  <span class="percentage-value"><strong>{{ percentage }}%</strong></span>
                </template>
                </el-progress>
              </td>
              <td v-html="highlightMatches(item.USR_PROTICK_DEPTO)"></td>
              <td v-html="highlightMatches(item.usr_prof)"></td>
              <td v-html="highlightMatches(item.USR_STTICK_FECHA_ALTA)"></td>
              <td v-html="highlightMatches(item.usr_ultima_activi)"></td>
            </tr>
            <tr>
              <td><strong>Resumen</strong></td>
              <td></td>
              <td></td>
              <td></td>
              <!-- <td v-if="getArea.area === 'it'">$ {{this.numberFormat(totalImputado * 1700,2)}}</td> -->
              <td v-if="getArea.area === 'it'"><strong>{{this.totalEstimado}}</strong></td>
              <td v-if="getArea.area === 'it'"><strong>{{this.totalOriginal}}</strong></td>
              <td v-if="getArea.area === 'it'"><strong>{{this.numberFormat(totalRevision,1)}}</strong></td>
              <td v-if="getArea.area === 'it'"><strong>{{totalDesvio}}</strong></td>
              <td><strong>{{this.numberFormat(totalImputado,1)}}</strong></td>
              <td v-if="getArea.area === 'it'"><strong>{{this.numberFormat(totalSaldo,1)}}</strong></td>
              <td></td>
              <td v-if="getArea.area === 'it'">
                <el-progress
                  :text-inside="false"
                  :stroke-width="10"
                  :percentage="this.numberFormat(totalImputado / (totalImputado + totalSaldo) * 100 ,2)"
                ><template #default="{ percentage }">
                  <span class="percentage-value"><strong>{{ percentage }}%</strong></span>
                </template>
                </el-progress>
              </td>
              <td v-if="getArea.area !== 'it'"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </body>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import config from '../../public/config.json'
import router from '../router'
import shared from '../shared/shared'
import back from '../components/back.vue'
import * as XLSX from 'xlsx'
import { Download } from '@element-plus/icons-vue'
export default {
  name: 'Tickets',
  props: {
    pestado: String,
    parea: String,
    precurrente: String
  },
  extends: shared,
  components: {
    back, Download
  },
  mounted: async function () {
    var self = this
    if (!this.isLoggedIn) {
      router.push('/')
      return
    }
    self.openFSL()
    this.sortBy = localStorage.getItem('sortBy') ? localStorage.getItem('sortBy') : this.sortBy
    this.way = localStorage.getItem('way') ? localStorage.getItem('way') : this.way
    document.title = 'Tickets'
    localStorage.removeItem('ticketsV2')
    await this.getProfesionals(self)
    if (self.$route.query.parea) {
      self.estado = self.$route.query.pestado
      self.departamento = self.$route.query.parea === 'DIR' ? '' : self.$route.query.parea
    } else {
      if (self.$route.query.precurrente) {
        self.estado = 'A'
        self.recurrente = 'S'
        // el user
        self.asignados.forEach(function (element) {
          if (self.getArea.user === element.usrlogin) {
            self.asignado = element.value
          }
        })
      } else {
        self.recurrente = localStorage.getItem('recurrente') ? localStorage.getItem('recurrente') : self.recurrente
        self.asignado = localStorage.getItem('asignado') ? localStorage.getItem('asignado') : self.asignado
        if (self.getArea.area === 'it') {
          self.departamento = localStorage.getItem('departamento') ? localStorage.getItem('departamento') : self.departamento
        } else {
          self.departamento = self.getArea.area
        }
        self.pendiente = localStorage.getItem('pendiente') ? localStorage.getItem('pendiente') : self.pendiente
        console.log('EXCLUSIVO')
        self.exclusivo = localStorage.getItem('exclusivo') ? localStorage.getItem('exclusivo') : false
        self.estado = localStorage.getItem('estado') ? localStorage.getItem('estado') : self.estado
        self.empresa = localStorage.getItem('empresa') ? localStorage.getItem('empresa') : self.empresa
        self.usuario = localStorage.getItem('usuario') ? localStorage.getItem('usuario') : self.usuario
        self.areaF = localStorage.getItem('areaF') ? localStorage.getItem('areaF') : self.areaF
        self.ticketF = localStorage.getItem('ticketF') ? localStorage.getItem('ticketF') : self.ticketF
        self.proyectoF = localStorage.getItem('proyectoF') ? localStorage.getItem('proyectoF') : self.proyectoF
        self.responsableF = localStorage.getItem('responsableF') ? localStorage.getItem('responsableF') : self.responsableF
        self.descripcionF = localStorage.getItem('descripcionF') ? localStorage.getItem('descripcionF') : self.descripcionF
        self.search = localStorage.getItem('search') ? localStorage.getItem('search') : self.search
        self.seek = localStorage.getItem('seek') ? localStorage.getItem('seek') : self.seek
        if (localStorage.getItem('ticketsV2')) {
          try {
            self.ticketsV2 = JSON.parse(localStorage.getItem('ticketsV2'))
          } catch (e) {
            localStorage.removeItem('ticketsV2')
          }
        }
      }
    }
    if (this.isLoggedIn) {
      await this.getUsers(self)
      self.usuarios = JSON.parse(localStorage.getItem('usuarios'))
      await this.getDepartamentos(self)
      self.departamentos = JSON.parse(localStorage.getItem('departamentos'))
      await this.getEmpresas(self)
      self.empresas = JSON.parse(localStorage.getItem('empresas'))
      await this.getTicketV2()
    }
    self.closeFSL()
  },
  watch: {
    way (newVal) {
      localStorage.setItem('way', newVal)
    },
    sortBy (newVal) {
      localStorage.setItem('sortBy', newVal)
    },
    seek (newVal) {
      localStorage.setItem('seek', newVal)
    },
    search (newVal) {
      localStorage.setItem('search', newVal)
    },
    empresa (val) {
      localStorage.setItem('empresa', val)
    },
    estado (val) {
      localStorage.setItem('estado', val)
    },
    asignado (val) {
      localStorage.setItem('asignado', val)
    },
    departamento (val) {
      localStorage.setItem('departamento', val)
    },
    pendiente (val) {
      localStorage.setItem('pendiente', val)
    },
    exclusivo (val) {
      localStorage.setItem('exclusivo', val)
    },
    usuario (val) {
      localStorage.setItem('usuario', val)
    },
    recurrente (val) {
      localStorage.setItem('recurrente', val)
    },
    proyectoF (newVal) {
      localStorage.setItem('proyectoF', newVal)
    },
    ticketF (newVal) {
      localStorage.setItem('ticketF', newVal)
    },
    responsableF (newVal) {
      localStorage.setItem('responsableF', newVal)
    },
    descripcionF (newVal) {
      localStorage.setItem('descripcionF', newVal)
    },
    areaF (newVal) {
      localStorage.setItem('areaF', newVal)
    }
  },
  data: function () {
    return {
      way: 'down',
      sortBy: 'alta',
      prioridad: '',
      xlsdetallado: false,
      loading: '',
      message: '',
      demorado: false,
      search: '',
      seek: '',
      tickets: [],
      ticketsV2: [],
      areaF: true,
      ticketF: true,
      proyectoF: true,
      responsableF: true,
      descripcionF: true,
      usuarios: [],
      usuario: '',
      recurrentes: [{
        value: 'S',
        label: 'Si'
      }, {
        value: 'N',
        label: 'No'
      }],
      recurrente: '',
      departamentos: [],
      departamento: '',
      asignados: [],
      asignado: '',
      pendiente: '',
      exclusivo: '',
      prioridades: [{
        value: 1,
        label: 'Priorizados'
      }, {
        value: -1,
        label: 'no priorizados'
      }],
      estados: [{
        value: 'A',
        label: 'Abiertos'
      }, {
        value: 'P',
        label: 'Sin Procesar'
      }, {
        value: 'S',
        label: 'Asignado'
      }, {
        value: 'D',
        label: 'Demorado'
      }, {
        value: 'U',
        label: 'Bloqueado'
      }, {
        value: 'C',
        label: 'En curso'
      }, {
        value: 'F',
        label: 'Finalizado'
      }],
      estado: '',
      empresas: [],
      empresa: ''
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['getArea']),
    ...mapGetters(['isLoggedIn']),
    filteredTkt () {
      return this.tickets
        .filter(this.filterById)
    },
    // filteredTktV2 () {
    //   return this.ticketsV2
    //     .filter(this.filterById)
    // },
    filteredTktV2 () {
      const sortedTickets = [...this.ticketsV2] // crea una copia de los tickets
      console.log('sort:')
      switch (this.sortBy) {
        case 'imputado':
          if (this.way === 'down') {
            sortedTickets.sort((a, b) => a.usr_imputadas - b.usr_imputadas)
          } else {
            sortedTickets.sort((a, b) => b.usr_imputadas - a.usr_imputadas)
          }
          break
        case 'proyecto':
          if (this.way === 'down') {
            sortedTickets.sort((a, b) => a.USR_PROYECTOS_NUMERO - b.USR_PROYECTOS_NUMERO)
          } else {
            sortedTickets.sort((a, b) => b.USR_PROYECTOS_NUMERO - a.USR_PROYECTOS_NUMERO)
          }
          break
        case 'alta':
          if (this.way === 'down') {
            sortedTickets.sort((a, b) => new Date(a.USR_STTICK_FECHA_ALTA) - new Date(b.USR_STTICK_FECHA_ALTA))
          } else {
            sortedTickets.sort((a, b) => new Date(b.USR_STTICK_FECHA_ALTA) - new Date(a.USR_STTICK_FECHA_ALTA))
          }
          break
        case 'actividad':
          if (this.way === 'down') {
            sortedTickets.sort((a, b) => new Date(a.usr_ultima_activi) - new Date(b.usr_ultima_activi))
          } else {
            sortedTickets.sort((a, b) => new Date(b.usr_ultima_activi) - new Date(a.usr_ultima_activi))
          }
          break
        // Agrega más casos para otros tipos de ordenamiento
        default:
          break
      }

      return sortedTickets.filter(this.filterById)
    },
    exportXLS () {
      var self = this
      var exportXLS = []
      this.filteredTktV2.forEach(function (item) {
        var estado = ''
        switch (item.USR_STTICK_ESTADO) {
          case 'A':
            estado = 'Abierto'
            break
          case 'P':
            estado = 'Sin Procesar'
            break
          case 'S':
            estado = 'Asignado'
            break
          case 'D':
            estado = 'Demorado'
            break
          case 'U':
            estado = 'Bloqueado'
            break
          case 'C':
            estado = 'En curso'
            break
          case 'F':
            estado = 'Finalizado'
            break
        }
        var obj
        if (self.xlsdetallado) {
          obj = {
            ticket: item.USR_STTICK_NROTICKET,
            descripcion: item.USR_STTICK_DESCRIP,
            area: item.USR_PROTICK_DEPTO,
            responsable: item.usr_prof,
            estimado: item.USR_HORAS,
            originales: item.usr_hs_originales,
            revision: item.usr_hs_revision,
            desvio: item.usr_desvio_proy,
            imputado: item.usr_imputadas,
            saldo: item.USR_SALDOHS,
            actividad: item.usr_ultima_activi,
            estado: estado,
            demora: item.usr_obses ? item.usr_obses.split('|')[0] : '',
            por: item.usr_obses ? item.usr_obses.split('|')[1] : '',
            observacion: item.usr_obses ? item.usr_obses.split('|')[2] : ''
          }
        } else {
          obj = {
            ticket: item.USR_STTICK_NROTICKET,
            descripcion: item.USR_STTICK_DESCRIP,
            area: item.USR_PROTICK_DEPTO,
            responsable: item.usr_prof,
            hs: item.usr_imputadas,
            actividad: item.usr_ultima_activi,
            estado: estado,
            demora: item.usr_obses ? item.usr_obses.split('|')[0] : '',
            por: item.usr_obses ? item.usr_obses.split('|')[1] : '',
            observacion: item.usr_obses ? item.usr_obses.split('|')[2] : ''
          }
        }
        exportXLS.push(obj)
      })
      return exportXLS
    },
    totalSaldo () {
      var res = 0
      this.filteredTktV2.forEach(function (item) {
        res = Number(res) + Number(item.USR_SALDOHS)
      }
      )
      return res
    },
    totalOriginal () {
      // return 140
      var res = 0
      this.filteredTktV2.forEach(function (item) {
        console.log(item.usr_hs_originales)
        res = Number(res) + Number(item.usr_hs_originales ? item.usr_hs_originales : 0)
      })
      return res
    },
    totalRevision () {
      var res = 0
      this.filteredTktV2.forEach(function (item) {
        res = Number(res) + Number(item.usr_hs_revision)
      }
      )
      return res
    },
    totalDesvio () {
      var res = 0
      this.filteredTktV2.forEach(function (item) {
        res = res + Number(item.usr_desvio_proy)
      }
      )
      return res
    },
    totalEstimado () {
      var res = 0
      this.filteredTktV2.forEach(function (item) {
        res = Number(res) + Number(item.USR_HORAS)
      }
      )
      return res
    },
    totalImputado () {
      var res = 0
      this.filteredTktV2.forEach(function (item) {
        res = res + Number(item.usr_imputadas)
      }
      )
      return res
    },
    totalProgreso () {
      var res = 0
      var cnt = 0
      this.filteredTktV2.forEach(function (item) {
        cnt++
        res = res + item.usr_progreso
      }
      )
      return res / cnt
    }
  },
  methods: {
    async getTicket () {
      this.openFSL()
      await this.getTicketV2()
      this.closeFSL()
    },
    ordenarPor (criterio) {
      if (this.sortBy === criterio) {
        if (this.way === 'down') {
          this.way = 'up'
        } else {
          this.way = 'down'
        }
      }
      this.sortBy = criterio
    },
    handleClick (ticket) {
      // router.push('/TicketInfo/' + ticket)
      router.replace('/?ticket=' + ticket)
    },
    focusInput () {
      this.$refs.isearch.focus()
    },
    downloadExcel (tableData) {
      const ws = XLSX.utils.json_to_sheet(tableData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1')
      XLSX.writeFile(wb, 'tickets.xlsx')
    },
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    closeFSL () {
      this.loading.close()
    },
    highlightMatches (text) {
      if (!text) return
      const matchExists = text
        .toLowerCase()
        .includes(this.search.toLowerCase())
      if (!matchExists) return text
      const re = new RegExp(this.search, 'ig')
      return text.replace(re, matchedText => `<strong>${matchedText}</strong>`)
    },
    filterById (imp) {
      if (this.search.length === 0) {
        return true
      }

      if (this.proyectoF.toString().toLowerCase() === 'false' & this.responsableF.toString().toLowerCase() === 'false' & this.descripcionF.toString().toLowerCase() === 'false' & this.areaF.toString().toLowerCase() === 'false' & this.ticketF.toString().toLowerCase() === 'false') {
        return true
      }

      const frase = this.search
      const palabras = frase.split(' ')
      const texto = imp.USR_DESCRIPCION_NROTKT.toString().toLowerCase()
      const resultados = palabras.map(palabra => {
        const regex = new RegExp(palabra, 'gi')
        const ocurrencias = texto.match(regex)
        return { palabra, ocurrencias }
      })
      var found = 0
      var count = 0
      resultados.forEach(function (item) {
        if (item.ocurrencias) {
          count++
        }
        if (count === resultados.length) {
          found = true
        }
      })
      console.log('sarasa:')
      console.log(resultados)
      return ((imp.USR_STTICK_NROTICKET.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.ticketF) ||
              (imp.USR_PROYECTOS_NUMERO && imp.USR_PROYECTOS_NUMERO.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.proyectoF) ||
              (imp.usr_prof && imp.usr_prof.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.responsableF) ||
              (found && this.descripcionF) ||
              (imp.USR_PROTICK_DEPTO.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.areaF) ||
              (imp.usr_obses && imp.usr_obses.split('|')[1].toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1)
      )
    },
    filterByrecurrente (imp) {
      if (this.recurrente === false) return true
      return (imp.estado.toString().toLowerCase() === 's' && this.recurrente)
    },
    getTicketV2: function (e) {
      var self = this
      // self.openFSL()
      if (!('exclusivo' in self)) {
        self.exclusivo = false
      }
      var url = 'http://' + config.ip + '/ticket/Sttick/listadoTtk/params;search=' + (self.seek ? self.seek : '') + ';tarea=;proyecto=;estado=' + (self.estado ? self.estado : '') + ';empresa=' + (self.empresa ? self.empresa : '') + ';profesional=' + (self.asignado ? self.asignado : '') + ';cPend=' + (localStorage.getItem('pendiente') === 'true' ? 'S' : 'N') + ';usuario=' + (self.usuario ? self.usuario : '') + ';recurrente=' + (self.recurrente ? self.recurrente : '') + ';departamento=' + (self.departamento ? self.departamento : '') + ';alta1=;alta2=;fin1=;fin2=;' + 'excl=' + (localStorage.getItem('exclusivo') === 'true' ? 'S' : 'N') + ';prioridad=' + (this.prioridad || '99') + ';'
      console.log('check:' + url)
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      return axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('ticketsV2', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.ticketsV2 = JSON.parse(localStorage.getItem('ticketsV2'))
          // self.closeFSL()
        })
        .catch(function (error) {
          self.errorMsg('Error inesperado, por favor comuniquese con area de sistemas, detalles: ' + error.message)
          // self.closeFSL()
        })
    },
    // getUsers: function (e) {
    //   var self = this
    //   self.openFSL()
    //   const url = 'http://' + config.ip + '/ticket/protick/'
    //   axios.get(url, {
    //     dataType: 'json',
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json',
    //       Authorization: self.getToken.token
    //     },
    //     mode: 'no-cors',
    //     credentials: 'include'
    //   })
    //     .then(function (response) {
    //       localStorage.setItem('usuarios', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
    //       self.usuarios = JSON.parse(localStorage.getItem('usuarios'))
    //       self.closeFSL()
    //     })
    //     .catch(function (error) {
    //       console.log(error)
    //       self.closeFSL()
    //     })
    // },
    // getDepartamentos: function (e) {
    //   var self = this
    //   self.openFSL()
    //   const url = 'http://' + config.ip + '/ticket/departamento/params;'
    //   axios.get(url, {
    //     dataType: 'json',
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json',
    //       Authorization: self.getToken.token
    //     },
    //     mode: 'no-cors',
    //     credentials: 'include'
    //   })
    //     .then(function (response) {
    //       localStorage.setItem('departamentos', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
    //       self.departamentos = JSON.parse(localStorage.getItem('departamentos'))
    //       // self.departamento = self.getArea.area
    //       self.closeFSL()
    //     })
    //     .catch(function (error) {
    //       console.log(error)
    //       self.closeFSL()
    //     })
    // },
    // getEmpresas: function (e) {
    //   var self = this
    //   self.openFSL()
    //   const url = 'http://' + config.ip + '/ticket/empresa;'
    //   axios.get(url, {
    //     dataType: 'json',
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json',
    //       Authorization: self.getToken.token
    //     },
    //     mode: 'no-cors',
    //     credentials: 'include'
    //   })
    //     .then(function (response) {
    //       localStorage.setItem('empresas', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
    //       self.empresas = JSON.parse(localStorage.getItem('empresas'))
    //       self.closeFSL()
    //     })
    //     .catch(function (error) {
    //       console.log(error)
    //       self.closeFSL()
    //     })
    // },
    currencyFormat (val) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })
      return formatter.format(val)
    }
  }
}
</script>
