<template>
<div v-if="isLoggedIn" class='container-fluid'>
  <br/>
  <back/>
  <br/>
  <div class='row'>
      <div class="col-md-12">
        <!-- <img alt='Vue logo' src='http://grupopenna.com.ar/images/why-choose.jpg'> -->
        <img alt='Vue logo' src='http://grupopenna.com.ar/images/logo-footerBIG.png'>
      </div>
      <div class="col-md-3">
      </div>
      <div class="col-md-6">
        <div class="row" v-if="this.isLoggedIn">
          <PieChart v-bind:chartData='chartState.chartData' v-bind:chartOptions='chartState.chartOptions' />
        </div>
      </div>
      <div class="col-md-3">
      </div>
  </div>
</div>
</template>
<style scoped>
img {
  display: relative;
  max-width: 90%;
  height: auto;
}
</style>
<script>
import PieChart from '../components/PieChart.vue'
import config from '../../public/config.json'
import { mapGetters } from 'vuex'
import axios from 'axios'
import back from '../components/back.vue'
export default {
  name: 'Home',
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getToken'])
  },
  components: {
    PieChart,
    back
  },
  data () {
    return {
      resxcat: [],
      chartState: {
        chartData: {
          datasets: [
            {
              data: [],
              backgroundColor: []
            }
          ],
          labels: []
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top'
            },
            title: {
              display: true,
              text: 'Chart.js Doughnut Chart'
            }
          }
        }
      }
    }
  },
  mounted () {
    if (this.isLoggedIn) {
      this.updateChart()
      this.updateChart()
    }
  },
  beforeMount () {
    document.title = 'Home'
  },
  methods: {
    aaa () {
      this.$refs.hijo.rendera()
    },
    updateChart () {
      var self = this
      const url = 'http://' + config.ip + '/ticket/Sttick/resxcat'
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('resxcat', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.resxcat = JSON.parse(localStorage.getItem('resxcat'))
          console.log(self.resxcat)
          self.chartState.chartData = {
            datasets: [
              {
                data: [self.resxcat[0].requisitos, self.resxcat[0].asistencias, self.resxcat[0].fallas],
                backgroundColor: [
                  'rgb(54, 162, 235)', 'rgb(255, 205, 86)', 'rgb(255, 99, 132)'
                ]
              }
            ],
            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: ['Requisitos', 'Asistencias', 'Fallas']
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }
}
</script>
