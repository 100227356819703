<template>
<div v-if="isLoggedIn" class="container-fluid">
  <back/>
  <form class = 'form-inline' role='form' @submit.prevent='getImpu'>
    <br/>
    <div class="row">
      <div class="col-md-3">
      </div>
      <div class="col-md-4">
        <span style='padding-right: 10px;'>Rango de fechas</span>
        <el-date-picker
          v-model="value1"
          type="daterange"
          unlink-panels
          range-separator="|"
          start-placeholder="Desde"
          end-placeholder="Hasta"
          :shortcuts="shortcuts"
        >
        </el-date-picker>
      </div>
      <div class="col-md-1">
        <button type='submit' class='btn btn-primary mb-2'>
          Buscar
        </button>
      </div>
      <div class="col-md-4">
      </div>
    </div>
  </form>
  <br/>
  <div class='row'>
    <div id='message' div class='col-md-12'>
      <el-input type='text' v-model='search' placeholder= 'Filtrar por...' />
      <div class='row'>
        <div class="form-group">
          <div class="form-check-inline">
            <input type="checkbox" v-model="unidadF">
            <label class="form-check-label" for="responsables"> Unidad</label>
          </div>
          <div class="form-check-inline">
            <input type="checkbox" v-model="choferF">
            <label class="form-check-label" for="responsables"> Chofer</label>
          </div>
        </div>
    </div><br>
    <div>
      <table id='table_id'
            class='table table-bordered table-condensed vue-table'
            >
        <thead>
          <tr class="blue">
              <th>Zona</th>
              <th>Fecha</th>
              <th>Unidad</th>
              <th>Chofer</th>
              <th>Tractor</th>
              <th>Acoplado</th>
              <th>Planta Ori</th>
              <th>Planta Des</th>
              <th>Fecha Inicio</th>
              <th>Fecha Fin</th>
              <th>Dia Viaje</th>
              <th>Hora inicio</th>
              <th>Hora fin</th>
              <th>Detalle</th>
              <th>Km Recorrido</th>
          </tr>
          <tr class="gray">
          <td><strong>Resumen</strong></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td><strong>{{sum()}}</strong></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for='(item, i) in filteredImpu' :key='i'>
            <td>{{item.zona}}</td>
            <td>{{item.fecha}}</td>
            <td v-html="highlightMatches(item.unidad)"></td>
            <td v-html="highlightMatches(item.chofer)"></td>
            <td>{{item.tractor}}</td>
            <td>{{item.acoplado}}</td>
            <td>{{item.plaori}}</td>
            <td>{{item.plades}}</td>
            <td>{{item.fechaini}}</td>
            <td>{{item.fechafin}}</td>
            <td>{{item.dini}}</td>
            <td>{{strToHour(item.hini)}}</td>
            <td>{{strToHour(item.hfin)}}</td>
            <td>{{item.detalle}}</td>
            <td>{{item.km}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
  </div>
</div>

</template>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
import router from '../router'
import shared from '../shared/shared'
import back from '../components/back.vue'
export default {
  components: {
    back
  },
  extends: shared,
  mounted: function () {
    if (!this.isLoggedIn) {
      this.errorMsg('Para continuar debe loggearse')
    }
    var self = this
    if (localStorage.getItem('usr_grtvil')) {
      try {
        self.impu = JSON.parse(localStorage.getItem('usr_grtvil'))
      } catch (e) {
        localStorage.removeItem('usr_grtvil')
      }
    }
    if (localStorage.getItem('unidadF')) {
      self.unidadF = localStorage.getItem('unidadF')
    }
    if (localStorage.getItem('choferF')) {
      self.choferF = localStorage.getItem('choferF')
    }
    if (localStorage.getItem('searchLarga')) {
      self.search = localStorage.getItem('searchLarga')
    }
    if (localStorage.getItem('fromlarga')) {
      self.value1 = [localStorage.getItem('fromlarga'), localStorage.getItem('toLarga')]
    }
  },
  watch: {
    value1 (newVal) {
      localStorage.setItem('fromlarga', newVal[0])
      localStorage.setItem('toLarga', newVal[1])
    },
    search (newVal) {
      localStorage.setItem('searchLarga', newVal)
    },
    unidadF (newVal) {
      localStorage.setItem('unidadF', newVal)
    },
    choferF (newVal) {
      localStorage.setItem('choferF', newVal)
    }
  },
  data: function () {
    return {
      colaboradores: [{
        value: 'NA',
        label: 'No Aplica'
      }, {
        value: 'S',
        label: 'Si'
      }, {
        value: 'N',
        label: 'No'
      }],
      colaborador: '',
      unidadF: true,
      choferF: true,
      date1: null,
      date2: null,
      value1: '',
      search: '',
      impu: [],
      areaF: true,
      ticketF: true,
      responsableF: true,
      descripcionF: true,
      shortcuts: [{
        text: '1 Week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        }
      }, {
        text: '2 Weeks',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 14)
          return [start, end]
        }
      }, {
        text: '1 Month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        }
      }, {
        text: '3 Months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        }
      }]
    }
  },
  computed: {
    ...mapGetters(['getToken']),
    ...mapGetters(['isLoggedIn']),
    filteredImpu () {
      return this.impu
        .filter(this.filterByProf)
    }
  },
  methods: {
    openFSL () {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    },
    errorMsg (val) {
      this.$message({
        message: val,
        type: 'error'
      })
    },
    closeFSL () {
      this.loading.close()
    },
    sum () {
      var res = 0
      this.filteredImpu.forEach(function (item) {
        res = res + item.km
      })
      return this.numberFormat(Math.round(res * 100) / 100)
    },
    highlightMatches (text) {
      if (!text) return
      const matchExists = text
        .toLowerCase()
        .includes(this.search.toLowerCase())
      if (!matchExists) return text
      const re = new RegExp(this.search, 'ig')
      return text.replace(re, matchedText => `<strong>${matchedText}</strong>`)
    },
    filterByProf (imp) {
      if (this.search.length === 0) {
        return true
      }
      // return true
      return ((imp.unidad.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.unidadF) ||
              (imp.chofer.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1 && this.choferF))
    },
    getImpu: function (e) {
      var self = this
      self.openFSL()
      const url = 'http://' + config.ip + '/comcam/usr_grtvil/fromto/fromto;from=' + self.strToDate(self.value1[0]) + ';to=' + self.strToDate(self.value1[1]) + ';zone=M1'
      axios.interceptors.response.use(function (response) {
        return response
      }, function (error) {
        if (error.response.status === 401) {
          router.push('/')
        }
        return Promise.reject(error)
      })
      axios.get(url, {
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: self.getToken.token
        },
        mode: 'no-cors',
        credentials: 'include'
      })
        .then(function (response) {
          localStorage.setItem('usr_grtvil', JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
          self.impu = JSON.parse(localStorage.getItem('usr_grtvil'))
          self.closeFSL()
        })
        .catch(function (error) {
          console.log(error)
          self.closeFSL()
        })
    },
    strToDate (str) {
      var date = new Date(str)
      var mnth = ('0' + (date.getMonth() + 1)).slice(-2)
      var day = ('0' + date.getDate()).slice(-2)
      return [date.getFullYear(), mnth, day].join('')
    },
    strToHour (str) {
      var ret = str.substring(0, 2) + ':'
      ret = ret + str.substring(2, 4)
      return ret
    },
    numberFormat (val) {
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0
      })
      return formatter.format(val)
    }
  }
}
</script>
